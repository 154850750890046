const dynamicDefinitions = [
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_MasteryPoints_T1",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "dynamicChangeDefinitionId": [
                                "Dac_ATH_MS_Coins_T1"
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "2700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "3400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "4100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "4900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "5800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 100
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 100
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_1000",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 800
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 800
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 800
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1100
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1100
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1100
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1800
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1800
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1800
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2300
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2600
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2600
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2600
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2700
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2700
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2700
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2800
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2800
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2800
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 2900
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 2900
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 2900
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_MasteryPoints_T3",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "dynamicChangeDefinitionId": [
                                "Dac_ATH_MS_Coins_T2"
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "4100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "5400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "6700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "8200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "9800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "11600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_MasteryPoints_T2",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "dynamicChangeDefinitionId": [
                                "Dac_ATH_MS_Coins_T1"
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "2200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "3100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "4000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "6100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "7300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "8700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "115000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "265000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "420000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "600000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "825000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1075000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1425000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1950000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_MasteryPoints_T5",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "dynamicChangeDefinitionId": [
                                "Dac_ATH_MS_Coins_T3"
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "4400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "6100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "10100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "12200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "14600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "17400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Food_80",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "19200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "33600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "52000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "76000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "96000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "116000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "135000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "160000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "165000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_MasteryPoints_T4",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "dynamicChangeDefinitionId": [
                                "Dac_ATH_MS_Coins_T2"
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "3700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "5100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "6700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "8400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "10200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "12200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "14500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_200",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_225",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "40500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "45000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "225000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "270000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "330000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "375000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "450000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "480000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_MasteryPoints_T6",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "dynamicChangeDefinitionId": [
                                "Dac_ATH_MS_Coins_T3"
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "7400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "10200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "13400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "16800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "20400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "24400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.mastery_points",
                                    "amount": "29000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_70",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "40000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "65000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "95000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "145000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "170000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_AscensionChest_GemShop",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_80_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "alabaster_idol",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "marble_bust",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_40",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_XXS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "17000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "22000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "41000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "46000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "47000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_120_XS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "10",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "10",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "40",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "40",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_4XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "1750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "4400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "5850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "5850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_4XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "1750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "4400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "5850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "5850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Evo_GoodsEach_300_S_Part3",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Evo_GoodsEach_300_S_Part2",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Evo_GoodsEach_300_S_Part1",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good2_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_75",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 60
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 60
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 85
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 130
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 170
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 195
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 195
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 195
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 205
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 205
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 205
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 210
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 210
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 210
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 220
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 220
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 220
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_500",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 395
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 560
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 560
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 560
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 875
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 875
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 875
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1100
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1100
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1100
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1300
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1300
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1300
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1300
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1400
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1400
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1400
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1400
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1400
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1400
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1500
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1500
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1500
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Food_210",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "135000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "305000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "360000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "425000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "445000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_EventHercules2021_DomesticFarm",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "35",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "65",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "65",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "65",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_450",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "4200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Incident_Good_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 150
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 300
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 320
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_60",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Incident_Good_2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 150
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 300
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 320
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_300_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "140",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "140",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Incident_Good_3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 150
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 300
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 320
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_PreviousEraGoodsEach_180_XS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "15",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "15",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "84",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "84",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "84",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_300_S_Part3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_300_S_Part1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_300_S_Part2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_SugarcaneDistillery",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "32000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "54000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "83000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_300",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BattleLostOffer_Food_400",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "160000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "260000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "380000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "480000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "580000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "685000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "800000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "840000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_VoodooHead",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "7900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "9800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Package_Coins_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "DawnAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "185000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "220000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "260000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "330000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_100_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "alabaster_idol",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "marble_bust",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Building_SeasonPass_Evolving_GreatGarden_1_UpgradeCosts",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-2",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "11",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-3",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "15",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-4",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "19",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "24",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-6",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "28",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-7",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "32",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-8",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "36",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-9",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "39",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                                    "amount": "-10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_90",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_125",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "17500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "22500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "81500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "119000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "182000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "213500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "251000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "263500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_60",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_600_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "200",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_40_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "alabaster_idol",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "marble_bust",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_PreviousEraGoodsEach_300_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "140",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "140",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T1",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_TeslaCrests_Common_10",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_10_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_10"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T2",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "7000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "7700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "8400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "9100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T3",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "7000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "11000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T4",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "16000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "22000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "24000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "26000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T5",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "33000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "36000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "39000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "42000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "45000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "48000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "51000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AscensionMaterial_Herbology",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "fragrant_potpourri",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "fragrant_potpourri",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "fragrant_potpourri",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "fragrant_potpourri",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbal_poultice",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbal_poultice",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbal_poultice",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbal_poultice",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "hortus_sanitatis",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "hortus_sanitatis",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "hortus_sanitatis",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Goods_4",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 270
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 270
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 270
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 350
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 350
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 350
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 460
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 460
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 460
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 600
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 780
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 780
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 780
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 350
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 350
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 350
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 460
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 460
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 460
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 600
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 600
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 780
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 780
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 780
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1010
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1010
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1010
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Goods_3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 130
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 170
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 220
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 220
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 220
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 290
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 290
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 290
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 380
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 380
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 380
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 170
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 220
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 220
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 220
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 290
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 290
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 290
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 380
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 380
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 380
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 490
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 490
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 490
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Goods_2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 85
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 140
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 85
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 140
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 180
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 180
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 180
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TeslaCrests_Green_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "green_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "green_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_green",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Green_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Goods_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 25
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 25
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 25
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 30
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 30
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 40
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 40
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 30
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 30
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 40
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 40
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 85
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 85
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AscensionMaterials_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_2_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_2",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_2",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_2",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_1_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_2",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_3",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_3",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_1_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension_3",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3_1"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_XpScrolls_25",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "125",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "225",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T6",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "35000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "65000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "88000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "96000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "104000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "112000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good2_XXXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "1850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T7",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "140000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "160000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "175000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "190000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "205000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_ScallywagsShanty",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "28000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "63000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "170000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Food_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "21000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "32500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "47500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "72500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "85000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "105000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good2_XXXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "1850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Coins_T8",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "240000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "270000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "330000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_80",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TreasureHunt_RR3_Wrapper",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_0",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_0_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_0_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_0_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_0_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_0"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_1_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_1_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_1_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_2_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_2_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_2_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_3_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_3_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_3_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_4",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_4_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_4_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_4_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_4_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_4"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_5",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_5_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_5_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_5_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_5_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_5"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_6",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_6_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_6_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_6_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_6_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "6"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_6"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_7",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_7_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_7_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_7_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_7_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "6"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_7"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR3_8",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR3_8_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_8_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_8_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR3_8_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "6"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_bronze"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR3_8"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Goods_5",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 400
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 400
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 400
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 520
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 520
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 520
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 680
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 680
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 680
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 880
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 880
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 880
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1140
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 510
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 510
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 510
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 660
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 660
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 660
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 860
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 860
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 860
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1120
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1120
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1120
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 1460
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 1460
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 1460
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_350",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "49000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "140000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "224000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "336000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "420000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "504000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "602000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "700000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "742000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AllianceCity_MainQuarter_Goods_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_1_"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "33"
                                                }
                                            ],
                                            "assetId": "icon_alliance_stone"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_3_"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "38"
                                                }
                                            ],
                                            "assetId": "icon_alliance_stone"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_4",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_4_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_4_"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "41"
                                                }
                                            ],
                                            "assetId": "icon_alliance_stone"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_4"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_6",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_6_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_6_"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "45"
                                                }
                                            ],
                                            "assetId": "icon_alliance_stone"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_6"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_7",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_7_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_7_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "90"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_7_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "138"
                                                }
                                            ],
                                            "chances": [
                                                50,
                                                50
                                            ],
                                            "assetId": "icon_chest_alliance_stone_timber",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_7"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "9",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_9",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_9_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_9_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "108"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_9_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "146"
                                                }
                                            ],
                                            "chances": [
                                                50,
                                                50
                                            ],
                                            "assetId": "icon_chest_alliance_stone_timber",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_9"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "10",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_10_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "116"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_10_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "152"
                                                }
                                            ],
                                            "chances": [
                                                50,
                                                50
                                            ],
                                            "assetId": "icon_chest_alliance_stone_timber",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "12",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_12",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_12_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_12_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "183"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_12_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "240"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_12_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "348"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_12"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "13",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_13",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_13_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_13_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "183"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_13_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "249"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_13_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "372"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_13"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "15",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_15",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_15_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_15_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "183"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_15_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "270"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_15_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "420"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_15"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "16",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_16",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_16_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_16_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "204"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_16_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "270"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_16_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "420"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_16"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "18",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_18",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_18_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_18_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "213"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_18_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "282"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_18_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "462"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_18"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "19",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_19",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_19_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_19_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "213"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_19_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "294"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_19_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "486"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_19"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "21",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_21",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_21_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_21_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "213"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_21_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "315"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_21_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "510"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_21"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "22",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_22",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_22_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_22_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "225"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_22_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "315"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_22_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "531"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_22"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "24",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_24",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_24_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_24_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "225"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_24_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "327"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_24_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "558"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_24"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "25",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_25",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_25_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_25_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "225"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_25_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "339"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_25_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "582"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_25"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "27",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_27",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_27_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_27_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "237"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_27_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "360"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_27_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "609"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_27"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "28",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_28",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_28_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_28_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "237"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_28_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "360"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_28_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "633"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_28"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "30",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_30_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "249"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_30_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "405"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_30_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "672"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "31",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_31",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_31_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_31_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "249"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_31_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "405"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_31_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "708"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_31"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "33",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_33",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_33_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_33_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "258"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_33_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "429"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_33_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "708"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_33"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "34",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_34",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_34_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_34_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "258"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_34_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "429"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_34_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "747"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_34"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "36",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_36",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_36_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_36_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "270"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_36_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "450"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_36_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "747"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_36"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "37",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_37",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_37_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_37_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "270"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_37_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "450"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_37_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "786"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_37"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "39",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_39",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_39_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_39_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "282"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_39_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "474"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_39_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "786"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_39"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "40",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_40",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_40_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_40_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "282"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_40_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "474"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_40_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "822"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_40"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "42",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_42",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_42_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_42_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "294"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_42_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "495"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_42_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "822"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_42"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "43",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_43",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_43_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_43_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "294"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_43_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "495"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_43_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "861"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_43"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "45",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_45",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_45_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_45_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "303"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_45_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "519"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_45_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "861"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_45"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "46",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_46",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_46_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_46_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "303"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_46_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "519"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_46_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "900"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_46"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "48",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_48",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_48_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_48_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "315"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_48_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "540"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_48_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "900"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_48"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "49",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_49",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_49_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_49_1"
                                                    },
                                                    "resource": "alliance_stone",
                                                    "amount": "315"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_49_2"
                                                    },
                                                    "resource": "alliance_timber",
                                                    "amount": "540"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_49_3"
                                                    },
                                                    "resource": "alliance_tiles",
                                                    "amount": "951"
                                                }
                                            ],
                                            "chances": [
                                                33,
                                                33,
                                                33
                                            ],
                                            "assetId": "icon_chest_alliance_goods_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_Goods_Chest_49"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_VideoReward_XP_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "225000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "420000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "650000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "910000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1520000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1880000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2800000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3380000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3740000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_GoodsEach_200",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "35",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "65",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "65",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "65",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_SkullHome",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "32000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "39000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "49000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_XXS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "8500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "19000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "32000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "39000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "58000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "78000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_500",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "295000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "510000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "695000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "985000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1600000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1950000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2450000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_LongNine",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 25
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 25
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 25
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 35
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 35
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 35
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_GoodsEach_180",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "18",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "18",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "18",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "84",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "84",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "84",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-3000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-3000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-10000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-15000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-20000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-25000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-30000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-40000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-50000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-60000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_2",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-6000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-6000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-20000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-30000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-40000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-50000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-60000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-80000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-100000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-120000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_75",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "130000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "160000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_3",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-12000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-12000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-40000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-60000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-80000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-100000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-120000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-160000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-200000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-240000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_4",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-25000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-25000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-80000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-120000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-160000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-200000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-240000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-320000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-400000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-480000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_250",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "145000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "255000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "350000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "490000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "630000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "790000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "980000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_5",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-50000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-50000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-160000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-240000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-320000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-400000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-480000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-640000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-800000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-960000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_6",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-100000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-100000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-320000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-480000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-640000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-800000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-960000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1280000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1600000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1920000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good3_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_AttemptCosts_7",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-200000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-200000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-600000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1000000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1000000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-2000000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-2000000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-3000000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-3000000"
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "treasure_hunt_attempt",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ],
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-4000000"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_80",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good3_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_4XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "900000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1670000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2580000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3620000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4720000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6070000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "7500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "11200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "13500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "14980000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Good2_Good3_240",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "35",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "170",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "170",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_40_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "bronze_bracelet",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "iron_pendant",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T8",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "130000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "170000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "190000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T4",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T5",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "16000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "22000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "24000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_900_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "400",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "400",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T6",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "7500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "15500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "23000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "28000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T7",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "40000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "85000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "95000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "105000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_60_XXS_Part1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T1",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T2",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Food_T3",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1650",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2550",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_CoconutTree",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "21000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "36000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "56000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_650",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "5600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "6300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_600_M_Part3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_80_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "bronze_bracelet",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "iron_pendant",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_4XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "460000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1670000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2400000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5700000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "7800000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8800000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_600_M_Part2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_600_M_Part1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_70",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_40",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_60_XXS_Part3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_60_XXS_Part2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_250",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "35000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "160000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "240000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "360000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "430000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "530000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good3_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good3_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good3_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_500",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "5250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "5250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "7000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "32000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "48000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "72000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "85000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "105000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_XXXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "1850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_1000",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "590000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1400000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1950000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3900000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4850000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_150",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "16000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "155000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "295000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "380000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "475000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "590000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "730000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "900000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_70",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_1800",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_100_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wool",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "linen_shirt",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Package_CapitalGoods_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "DawnAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "215",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "215",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "215",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "320",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "320",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "320",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_110",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AscensionMaterial_DeviceTimeSpace",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "shadow_dial",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "shadow_dial",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "shadow_dial",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "shadow_dial",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "charta_terrestre",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "charta_terrestre",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "charta_terrestre",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "charta_terrestre",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "travelers_compass",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "travelers_compass",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "travelers_compass",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Good2_Good3_80",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "12",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "12",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "19000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "44000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "138000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "179000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "238000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "325000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "367000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "375000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "40000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "140000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "275000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "360000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "475000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "650000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "735000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "750000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good2_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_90",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_60",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 60
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "9500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "22000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "35000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "69000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "119000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "163000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "183000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "188000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AllianceCity_MainQuarter_AlliancePoints_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_1_1"
                                                    },
                                                    "resource": "alliance_point",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_1_2"
                                                    },
                                                    "resource": "alliance_point_rare",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_1_3"
                                                    },
                                                    "resource": "alliance_point_epic",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_2_2"
                                                    },
                                                    "resource": "alliance_point_rare",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_2_3"
                                                    },
                                                    "resource": "alliance_point_epic",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_3_3"
                                                    },
                                                    "resource": "alliance_point_epic",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_4",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_4_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_2"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                970,
                                                                30
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_4"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_5",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_5_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                850,
                                                                100,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_3"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                970,
                                                                30
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_5"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_6",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_6_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                850,
                                                                100,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                970,
                                                                30
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_4"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_6"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_7",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_7_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                850,
                                                                100,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_5"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                940,
                                                                60
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_7"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_8",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_8_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                725,
                                                                200,
                                                                75
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                950,
                                                                50
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_6"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                940,
                                                                60
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_8"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "9",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_9",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_9_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                725,
                                                                200,
                                                                75
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                880,
                                                                110,
                                                                10
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                }
                                                            ],
                                                            "chances": [
                                                                940,
                                                                60
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_7"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_9"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "10",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                725,
                                                                200,
                                                                75
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_8"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                880,
                                                                110,
                                                                10
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                900,
                                                                90,
                                                                10
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "11",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_11",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_11_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                625,
                                                                250,
                                                                125
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                880,
                                                                110,
                                                                10
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_9"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                900,
                                                                90,
                                                                10
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_11"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "12",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_12",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_12_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                625,
                                                                250,
                                                                125
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                820,
                                                                140,
                                                                40
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                900,
                                                                90,
                                                                10
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_10"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_12"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "13",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_13",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_13_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                625,
                                                                250,
                                                                125
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_11"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                820,
                                                                140,
                                                                40
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                855,
                                                                120,
                                                                25
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_13"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "14",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_14",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_14_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                500,
                                                                350,
                                                                150
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                820,
                                                                140,
                                                                40
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_12"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                855,
                                                                120,
                                                                25
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_14"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "15",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_15",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_15_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                500,
                                                                350,
                                                                150
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                760,
                                                                170,
                                                                70
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                855,
                                                                120,
                                                                25
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_13"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_15"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "16",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_16",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_16_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                500,
                                                                350,
                                                                150
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_14"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                760,
                                                                170,
                                                                70
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                810,
                                                                150,
                                                                40
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_16"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "17",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_17",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_17_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                400,
                                                                200
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                760,
                                                                170,
                                                                70
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_15"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                810,
                                                                150,
                                                                40
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_17"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "18",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_18",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_18_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                400,
                                                                200
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                700,
                                                                200,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                810,
                                                                150,
                                                                40
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_16"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_18"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "19",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_19",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_19_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                400,
                                                                200
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_17"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                700,
                                                                200,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                765,
                                                                180,
                                                                55
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_19"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "20",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                275,
                                                                500,
                                                                225
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                700,
                                                                200,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_18"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                765,
                                                                180,
                                                                55
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "21",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_21",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_21_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                275,
                                                                500,
                                                                225
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                640,
                                                                230,
                                                                130
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                765,
                                                                180,
                                                                55
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_19"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_21"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "22",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_22",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_22_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                275,
                                                                500,
                                                                225
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_20"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                640,
                                                                230,
                                                                130
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                720,
                                                                210,
                                                                70
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_22"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "23",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_23",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_23_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                175,
                                                                550,
                                                                275
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                640,
                                                                230,
                                                                130
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_21"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                720,
                                                                210,
                                                                70
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_23"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "24",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_24",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_24_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                175,
                                                                550,
                                                                275
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                580,
                                                                260,
                                                                160
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                720,
                                                                210,
                                                                70
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_22"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_24"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "25",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_25",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_25_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                175,
                                                                550,
                                                                275
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_23"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                580,
                                                                260,
                                                                160
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                675,
                                                                240,
                                                                85
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_25"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "26",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_26",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_26_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                50,
                                                                650,
                                                                300
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                580,
                                                                260,
                                                                160
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_24"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                675,
                                                                240,
                                                                85
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_26"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "27",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_27",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_27_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                50,
                                                                650,
                                                                300
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                520,
                                                                290,
                                                                190
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                675,
                                                                240,
                                                                85
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_25"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_27"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "28",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_28",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_28_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                50,
                                                                650,
                                                                300
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_26"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                520,
                                                                290,
                                                                190
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                630,
                                                                270,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_28"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "29",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_29",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_29_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                625,
                                                                350,
                                                                25
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                520,
                                                                290,
                                                                190
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_27"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                630,
                                                                270,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_29"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "30",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                625,
                                                                350,
                                                                25
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                460,
                                                                320,
                                                                220
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                630,
                                                                270,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_28"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "31",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_31",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_31_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                625,
                                                                350,
                                                                25
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_29"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                460,
                                                                320,
                                                                220
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                585,
                                                                300,
                                                                115
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_31"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "32",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_32",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_32_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                525,
                                                                400,
                                                                75
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                460,
                                                                320,
                                                                220
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_30"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                585,
                                                                300,
                                                                115
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_32"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "33",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_33",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_33_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                525,
                                                                400,
                                                                75
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                350,
                                                                250
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                585,
                                                                300,
                                                                115
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_31"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_33"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "34",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_34",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_34_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                525,
                                                                400,
                                                                75
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_32"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                350,
                                                                250
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                540,
                                                                330,
                                                                130
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_34"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "35",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_35",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_35_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                500,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                350,
                                                                250
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_33"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                540,
                                                                330,
                                                                130
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_35"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "36",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_36",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_36_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                500,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                340,
                                                                380,
                                                                280
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                540,
                                                                330,
                                                                130
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_34"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_36"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "37",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_37",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_37_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                400,
                                                                500,
                                                                100
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_35"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                340,
                                                                380,
                                                                280
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                495,
                                                                360,
                                                                145
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_37"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "38",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_38",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_38_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                300,
                                                                550,
                                                                150
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                340,
                                                                380,
                                                                280
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_36"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                495,
                                                                360,
                                                                145
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_38"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "39",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_39",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_39_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                300,
                                                                550,
                                                                150
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                280,
                                                                410,
                                                                310
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                495,
                                                                360,
                                                                145
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_37"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_39"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "40",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_40",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_40_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                300,
                                                                550,
                                                                150
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_38"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                280,
                                                                410,
                                                                310
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                450,
                                                                390,
                                                                160
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_40"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "41",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_41",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_41_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                175,
                                                                650,
                                                                175
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                280,
                                                                410,
                                                                310
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_39"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                450,
                                                                390,
                                                                160
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_41"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "42",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_42",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_42_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                175,
                                                                650,
                                                                175
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                220,
                                                                440,
                                                                340
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                450,
                                                                390,
                                                                160
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_40"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_42"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "43",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_43",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_43_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                175,
                                                                650,
                                                                175
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_41"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                220,
                                                                440,
                                                                340
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                405,
                                                                420,
                                                                175
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_43"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "44",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_44",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_44_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                75,
                                                                700,
                                                                225
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                220,
                                                                440,
                                                                340
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_42"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                405,
                                                                420,
                                                                175
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_44"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "45",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_45",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_45_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                75,
                                                                700,
                                                                225
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                160,
                                                                470,
                                                                370
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                405,
                                                                420,
                                                                175
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_43"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_45"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "46",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_46",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_46_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                }
                                                            ],
                                                            "chances": [
                                                                75,
                                                                700,
                                                                225
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_44"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                160,
                                                                470,
                                                                370
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                360,
                                                                450,
                                                                190
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_46"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "47",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_47",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_47_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "5"
                                                                }
                                                            ],
                                                            "chances": [
                                                                730,
                                                                250,
                                                                20
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                160,
                                                                470,
                                                                370
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_45"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                360,
                                                                450,
                                                                190
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_47"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "48",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_48",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_48_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "5"
                                                                }
                                                            ],
                                                            "chances": [
                                                                730,
                                                                250,
                                                                20
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                100,
                                                                500,
                                                                400
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                360,
                                                                450,
                                                                190
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_46"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_48"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "49",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_49",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_49_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_1"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "3"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_2"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "4"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47_3"
                                                                    },
                                                                    "resource": "alliance_point",
                                                                    "amount": "5"
                                                                }
                                                            ],
                                                            "chances": [
                                                                730,
                                                                250,
                                                                20
                                                            ],
                                                            "assetId": "icon_chest_alliance_points",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_47"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_1"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_2"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48_3"
                                                                    },
                                                                    "resource": "alliance_point_rare",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                100,
                                                                500,
                                                                400
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_rare",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Rare_48"
                                                    }
                                                },
                                                {
                                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                                    "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_49",
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_49_Id"
                                                            },
                                                            "rewards": [
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_49_1"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "1"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_49_2"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "2"
                                                                },
                                                                {
                                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                                    "baseData": {
                                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_49_3"
                                                                    },
                                                                    "resource": "alliance_point_epic",
                                                                    "amount": "3"
                                                                }
                                                            ],
                                                            "chances": [
                                                                315,
                                                                480,
                                                                205
                                                            ],
                                                            "assetId": "icon_chest_alliance_points_epic",
                                                            "randomSeedCounter": "default"
                                                        }
                                                    ],
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Epic_49"
                                                    }
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                23,
                                                7
                                            ],
                                            "assetId": "icon_chest_alliance_points_all",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_AlliancePoints_Chest_49"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_275",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "38500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "176000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "264000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "330000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "396000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "473000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "550000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "583000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_80_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wool",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "linen_shirt",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_150",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "27000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "220000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "320000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Building_TreasureHunt_Evolving_FountainOfYouth_1_RiftTokens",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "2",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "3",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "4",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "10",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "13",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "7",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "15",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "8",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "17",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "9",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "20",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "22",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "11",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "24",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "12",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "27",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "14",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "30",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "32",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "16",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "34",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "17",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "36",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "18",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "40",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "rift_token_common_part",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good2_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good2_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_LegendaryPullOffer_Food_125",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "260000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TeslaCrests_Blue_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "blue_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "blue_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_blue",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Blue_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good2_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good2_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_RP_5",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TreasureHunt_GroupRankReward_3_RP",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "3",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "4",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "7",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "7",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "8",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "9",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "11",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good3_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_500",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "325000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "475000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "600000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "725000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "855000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_90",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Building_TreasureHunt_Evolving_FountainOfYouth_1_UpgradeCosts",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-2",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-3",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "11",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-4",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "13",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "15",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-6",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "17",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-7",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "19",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-8",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "21",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-9",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "23",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "25",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-11",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "27",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-12",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "29",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-13",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "31",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-14",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "33",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                    "amount": "-15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_LegendaryPullOffer_XP_540",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "7500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_OneTimeBuyerOffer_XP_450",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_2200",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "7500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "13500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "21000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good3_XXXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "1850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_25",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "16000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "24000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "36500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "42500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "52500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_1700",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "215000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "450000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2400000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2800000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TreasureHunt_RR2_Wrapper",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_0",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_0_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_0_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_0_Reward_2"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_0_Reward_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "6"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_0"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_1_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_1_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "1000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_1_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "100"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_1_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "6"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_2_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_2_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "1300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_2_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_2_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "6"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_3_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_3_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "1800"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_3_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_3_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "9"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_4",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_4_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_4_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_4_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "2300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_4_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_4_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "9"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_4"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_5",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_5_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_5_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_5_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "2800"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_5_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_5_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "9"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_5"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_6",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_6_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_6_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_6_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "3300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_6_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_6_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "12"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_6"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_7",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_7_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_7_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_7_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "4000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_7_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_7_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "12"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_7"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR2_8",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR2_8_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_8_Reward_1"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_8_Reward_2"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "5000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_8_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "1500"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR2_8_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "12"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_silver"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR2_8"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AscensionMaterial_BattleInsignia",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "war_horn",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "war_horn",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "war_horn",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "war_horn",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "crested_guidon",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "crested_guidon",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "crested_guidon",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "crested_guidon",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gilded_pennant",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gilded_pennant",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gilded_pennant",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_80",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 80
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 80
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 80
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 175
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 175
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 175
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 225
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 225
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 225
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 265
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 265
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 265
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 270
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 270
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 270
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 280
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 280
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 280
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 290
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 290
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 290
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good3_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_1200_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "200",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "400",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "400",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "550",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "550",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "550",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_75",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "45000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "75000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "105000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "190000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "240000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "370000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "450000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_LegendaryPullOffer_Food_75",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "48000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "72000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "90000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "108000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "156000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_175",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "23500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "31500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "35000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "116500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "175000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "256500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "291500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "350000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "373500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TeslaCrests_Purple_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "purple_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "purple_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_purple",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Purple_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good3_XXXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "1850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Goods_T6",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 180
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 180
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 180
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "250",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "250",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "320",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "320",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "320",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "390",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "390",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "390",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "460",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "460",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "460",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "530",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "530",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "530",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "600",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "650",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "650",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "650",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "700",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "700",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "700",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Goods_T3",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 75
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 75
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 75
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "125",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "125",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "125",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "140",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "140",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "140",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "155",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "155",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "155",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "170",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "170",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "170",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "185",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "185",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "185",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "200",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "200",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "215",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "215",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "215",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "230",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "230",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "230",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_EventCelts2022_RuralFarm",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "45",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "45",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "21000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "210",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "32500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "225",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "47500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "225",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "225",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "75000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "225",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "85000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "225",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "225",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "105000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Goods_T2",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "70",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "70",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "80",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "80",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "90",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "90",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "100",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "110",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "110",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "130",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "130",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "130",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Goods_T5",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 140
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "205",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "205",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "205",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "270",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "270",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "270",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "335",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "335",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "335",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "400",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "400",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "430",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "430",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "430",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "460",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "460",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "460",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "490",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "490",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "490",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "520",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "520",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "520",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Goods_T4",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "180",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "180",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "250",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "250",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "270",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "270",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "270",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "290",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "290",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "290",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "310",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "310",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "310",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "330",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "330",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "350",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "350",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "370",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "370",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "370",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TeslaCrests_Yellow_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "yellow_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "yellow_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_yellow",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Yellow_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 100
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 100
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_MS_Goods_T1",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 20
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 20
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "35",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "35",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "40",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "40",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "45",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "45",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "45",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "55",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "55",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "55",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "60",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_200",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "24000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "116000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "204000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "280000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "392000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "504000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "632000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "784000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "960000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_40_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wool",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "linen_shirt",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 16
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 16
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 16
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 22
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 22
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 22
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 36
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 36
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 36
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 44
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 44
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 44
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 52
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 52
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 52
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 52
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 52
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 52
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 52
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 52
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 52
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 54
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 54
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 54
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 56
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 56
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 56
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 58
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 58
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 58
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_XXXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "1850",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "2450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_60_XXS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "5",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "5",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "10",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "10",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_25",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 20
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 20
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 30
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 30
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 45
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 45
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 45
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 55
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 55
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 55
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 65
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 65
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 70
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 70
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 70
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 70
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 70
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 75
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 75
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 75
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_10",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "45000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "55000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "65000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good2_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "1100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "1500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Building_SeasonPass_Evolving_GreatGarden_1_RP_Chest",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_1_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_1_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                95,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_2_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_2_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                85,
                                                15
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_3_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_3_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_3_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                75,
                                                20,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "9",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_4",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_4_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_4_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_4_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_4_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                65,
                                                25,
                                                10
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_4"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "11",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_5",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_5_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_5_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_5_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_5_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_5_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                }
                                            ],
                                            "chances": [
                                                55,
                                                30,
                                                10,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_5"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "13",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_6",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_6_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_6_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_6_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_6_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_6_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                }
                                            ],
                                            "chances": [
                                                45,
                                                35,
                                                10,
                                                10
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_6"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "15",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_7",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_7_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_7_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_7_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_7_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_7_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                }
                                            ],
                                            "chances": [
                                                30,
                                                45,
                                                15,
                                                10
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_7"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "18",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_8",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_8_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_8_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_8_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_8_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_8_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                }
                                            ],
                                            "chances": [
                                                20,
                                                55,
                                                15,
                                                10
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_8"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "20",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_9",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_9_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_9_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_9_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_9_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_9_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_9_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                }
                                            ],
                                            "chances": [
                                                10,
                                                60,
                                                15,
                                                10,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_9"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "22",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_10",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_10_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_10_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_10_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_10_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_10_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                }
                                            ],
                                            "chances": [
                                                70,
                                                15,
                                                10,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_10"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "26",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_11",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_11_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_11_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_11_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_11_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_11_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                }
                                            ],
                                            "chances": [
                                                60,
                                                25,
                                                10,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_11"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "28",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_12",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_12_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_12_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_12_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_12_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_12_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                }
                                            ],
                                            "chances": [
                                                55,
                                                25,
                                                15,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_12"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "30",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_13",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_13_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_13_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_13_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_13_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_13_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                }
                                            ],
                                            "chances": [
                                                50,
                                                25,
                                                15,
                                                10
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_13"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "33",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_14",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_14_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_14_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_14_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_14_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_14_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                }
                                            ],
                                            "chances": [
                                                45,
                                                25,
                                                15,
                                                15
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_14"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "35",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_15",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_15_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_15_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_15_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_15_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_15_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_15_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                45,
                                                20,
                                                15,
                                                15,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_15"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "37",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_16",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_16_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_16_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_16_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_16_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_16_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_16_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                40,
                                                20,
                                                20,
                                                15,
                                                5
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_16"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "39",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_Evo_GreatGarden_RP_Chest_17",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Evo_GreatGarden_RP_Chest_17_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_17_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_17_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_17_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_17_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "7"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_Evo_GreatGarden_RP_Chest_17_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                35,
                                                20,
                                                20,
                                                15,
                                                10
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_Evo_GreatGarden_RP_Chest_17"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_100_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "bronze_bracelet",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "iron_pendant",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_TeslaCrests_Enhanced_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "8"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "8"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_20_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "8"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_20_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_20"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_RP_5",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_RP_3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_RP_4",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "80",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_RP_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "7",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "9",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_RP_2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "19",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_GoodsEach_150",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "15",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "15",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "25",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "25",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "70",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "70",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Building_TreasureHunt_Evolving_FountainOfYouth_1_RP",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "1",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "12",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "2",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "25",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "3",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "38",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "4",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_10",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 8
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 8
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 8
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 11
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 11
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 11
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 18
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 18
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 18
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 22
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 22
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 22
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 26
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 26
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 26
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 26
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 26
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 26
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 26
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 26
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 26
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 27
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 27
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 27
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 28
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 28
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 28
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 29
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 29
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 29
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AllianceCity_MainQuarter_ResearchPoints_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/BuildingLevelDynamicChangeDTO",
                "values": [
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_1_"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                }
                                            ],
                                            "assetId": "icon_research_points"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_2_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_2_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                }
                                            ],
                                            "chances": [
                                                950,
                                                50
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_5",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_5_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_5_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_5_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_5_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                850,
                                                100,
                                                50
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_5"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_8",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_8_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_8_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_8_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_8_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                725,
                                                200,
                                                75
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_8"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "11",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_11",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_11_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_11_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_11_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_11_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                625,
                                                250,
                                                125
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_11"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "14",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_14",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_14_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_14_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_14_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_14_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                500,
                                                350,
                                                150
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_14"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "17",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_17",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_17_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_17_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_17_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_17_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                400,
                                                400,
                                                200
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_17"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "20",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_20",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_20_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_20_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_20_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_20_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                275,
                                                500,
                                                225
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_20"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "23",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_23",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_23_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_23_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_23_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_23_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                175,
                                                550,
                                                275
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_23"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "26",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_26",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_26_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_26_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_26_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_26_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                }
                                            ],
                                            "chances": [
                                                50,
                                                650,
                                                300
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_26"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "29",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_29",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_29_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_29_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_29_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_29_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                625,
                                                350,
                                                25
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_29"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "32",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_32",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_32_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_32_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_32_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_32_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                525,
                                                400,
                                                75
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_32"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "35",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_35",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_35_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_35_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_35_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_35_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                400,
                                                500,
                                                100
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_35"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "38",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_38",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_38_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_38_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_38_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_38_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                300,
                                                550,
                                                150
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_38"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "41",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_41",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_41_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_41_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_41_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_41_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                175,
                                                650,
                                                175
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_41"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "44",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_44",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_44_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_44_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_44_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_44_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                }
                                            ],
                                            "chances": [
                                                75,
                                                700,
                                                225
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_44"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "47",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_47",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_47_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_47_1"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_47_2"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "4"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_47_3"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "5"
                                                }
                                            ],
                                            "chances": [
                                                730,
                                                250,
                                                20
                                            ],
                                            "assetId": "icon_chest_rp",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AllianceCity_MainQuarter_ResearchPoints_47"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_LegendaryPullOffer_Food_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "32000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "48000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "72000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "104000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_AscensionMaterials_1000all",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_5all_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_2"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_2"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_2"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier2_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier2_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_1"
                                                    },
                                                    "resource": "herbal_poultice",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_2"
                                                    },
                                                    "resource": "crested_guidon",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier2_5all_3"
                                                    },
                                                    "resource": "charta_terrestre",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_2"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier2_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_3"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_3"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3_5all"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier3_5all",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier3_5all_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_1"
                                                    },
                                                    "resource": "hortus_sanitatis",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_2"
                                                    },
                                                    "resource": "gilded_pennant",
                                                    "amount": "5"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier3_5all_3"
                                                    },
                                                    "resource": "travelers_compass",
                                                    "amount": "5"
                                                }
                                            ],
                                            "assetId": "icon_chest_ascension_3"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier3_5all"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_110",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "650",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "125000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "160000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_SeasonPass_Good1_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/SeasonPassAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ],
                "seasonPassGroup": "SeasonPass"
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_60_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "alabaster_idol",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "marble_bust",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "column",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_120_XS_Part1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_120_XS_Part2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_120_XS_Part3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "11000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "140000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "320000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "400000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "480000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "600000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_225",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "27000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "130500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "229500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "315000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "441000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "567000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "711000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "882000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1080000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1350000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Food_120",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "7200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "14400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "28800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "50400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "78000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "114000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "144000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "174000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "205000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "240000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 100
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 100
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_300",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "42000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "192000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "288000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "360000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "432000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "516000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "600000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "636000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_250",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 195
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 195
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 280
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 280
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 280
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 440
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 440
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 440
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 560
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 560
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 560
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 655
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 655
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 655
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 655
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 655
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 655
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 655
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 655
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 655
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 680
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 680
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 680
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 705
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 705
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 705
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 730
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 730
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 730
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 20
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good3_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_EventHercules2021_RuralFarm",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "40000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "85000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "105000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "125000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "147000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "173000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "181000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_BuyingAttempts_Coins_BaseCost",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-400000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-600000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-750000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-950000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "costs": [
                                {
                                    "@type": "type.googleapis.com/ResourceDTO",
                                    "definitionId": "coins",
                                    "amount": "-1700000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good1_XXL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "8350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "70",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "1450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "1950",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 24
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 24
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 24
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 33
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 33
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 33
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 54
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 54
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 54
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 66
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 66
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 66
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 78
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 78
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 78
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 78
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 78
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 78
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 78
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 78
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 78
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 81
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 81
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 81
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 84
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 84
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 84
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 87
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 87
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 87
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "130000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "415000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "540000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "715000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "975000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1125000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_1000",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "135000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "190000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "385000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "650000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "950000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1450000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1700000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TreasureHunt_GroupRankReward_2_RP",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "5",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "6",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "8",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "9",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "11",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "12",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "14",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "17",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "19",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "18000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "42000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "58500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "75000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "94500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "117000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "145500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "180000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good2_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good2_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good2_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "250",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Customization_EventWorldFair2024_Farm_Rural",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_AscensionChest_Tier1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_AscensionChest_Tier1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_1"
                                                    },
                                                    "resource": "war_horn",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_2"
                                                    },
                                                    "resource": "shadow_dial",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_AscensionChest_Tier1_1_3"
                                                    },
                                                    "resource": "fragrant_potpourri",
                                                    "amount": "1"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_ascension",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_AscensionChest_Tier1_1"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_LegendaryPullOffer_XP_900",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "4500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "7500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_XL",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "115000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "320000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "450000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "590000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "760000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "940000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1400000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1690000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1870000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_OneTimeBuyerOffer_Food_450",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "85000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "240000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "360000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1650000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "3800000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6500000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "10000000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good2_60_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "bronze_bracelet",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "iron_pendant",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "silver_ring",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "gold_laurel",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "goblet",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "parchment",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "ink",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "manuscript",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wax_seal",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tome",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_25",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "25500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "35000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "65000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Food_XS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "1050",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "5000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "11000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "17000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "34000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "45000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "59000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "81000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "92000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "94000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_TeslaCrests_Superior_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "12"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_2"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_3"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "12"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_30_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "12"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_common",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "9"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "9"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Enhanced_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Enhanced_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_1"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_2"
                                                    },
                                                    "resource": "green_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_3"
                                                    },
                                                    "resource": "blue_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_4"
                                                    },
                                                    "resource": "yellow_crest_enhanced",
                                                    "amount": "9"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Enhanced_30_5"
                                                    },
                                                    "resource": "purple_crest_enhanced",
                                                    "amount": "9"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_enhanced",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Enhanced_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Superior_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Superior_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_1"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_2"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_4"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_5"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_superior",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Superior_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Superior_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Superior_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_1"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_2"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_4"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_5"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_superior",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Superior_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Superior_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Superior_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_1"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_2"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_4"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_5"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_superior",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Superior_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Superior_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Superior_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_1"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_2"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_4"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_5"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_superior",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Superior_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Superior_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Superior_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_1"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_2"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_4"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_5"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_superior",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Superior_30"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Superior_30",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Superior_30_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_1"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_2"
                                                    },
                                                    "resource": "green_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_3"
                                                    },
                                                    "resource": "blue_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_4"
                                                    },
                                                    "resource": "yellow_crest_superior",
                                                    "amount": "6"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Superior_30_5"
                                                    },
                                                    "resource": "purple_crest_superior",
                                                    "amount": "6"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_superior",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Superior_30"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_Food_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "24000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "42000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "65000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "95000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "145000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "170000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "210000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_EventCity_XpScrolls_100",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_S",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "19000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "35000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "54000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "75000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "98000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "126000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "156000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "233000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "281000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "312000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "12000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "28000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "39000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "63000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "78000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "97000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_XS",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "9500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "17500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "27000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "38000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "49000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "63000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "78000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "117000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "141000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "156000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Coins_5",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "195000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "255000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "330000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "430000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "560000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "730000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "950000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_BelltowerCottage",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 25
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 25
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 25
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 50
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 50
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Coins_3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "50000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "65000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "84500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "145000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "190000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "245000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "320000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "38000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "70000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "110000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "195000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "255000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "315000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "465000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "565000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "625000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BattleLostOffer_XP_400",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "2600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "4000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Coins_4",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "100000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "130000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "170000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "220000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "285000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "370000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "480000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "625000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Food_200",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "26500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "36000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "40000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "133500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "200500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "240500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "294000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "334000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "401000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "427500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Coins_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "4000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "75000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "140000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "220000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "300000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "390000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "510000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "630000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "930000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1130000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1250000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 50
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "50",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_LegendaryPullOffer_XP_360",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1200",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "1800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "resource.hero_xp",
                                    "amount": "3600",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Coins_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "9000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "11500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "15000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "19500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "25500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "33000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "43000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "56000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_BuildingCustomization_TreasureHunt_PirateDen",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "2900",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "5100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "7000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "9800",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "13000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "16000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "24000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "30000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_CityBundle_Coins_2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "26000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "34000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "44000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "57000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "74000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "96000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "125000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_150",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 120
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 120
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 120
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 170
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 170
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 265
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 265
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 335
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 335
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 335
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 395
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 395
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 395
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 395
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 405
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 405
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 405
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 420
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 420
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 420
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 440
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 440
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 440
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_RP_10",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TeslaCrests_Red_20",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "red_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "red_crest_common",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_2_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "20"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_2"
                                                    },
                                                    "resource": "red_crest_enhanced",
                                                    "amount": "15"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior_3"
                                                    },
                                                    "resource": "red_crest_superior",
                                                    "amount": "10"
                                                }
                                            ],
                                            "chances": [
                                                100,
                                                100,
                                                100
                                            ],
                                            "assetId": "icon_chest_teslacrests_3_red",
                                            "randomSeedCounter": "default"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Red_Common_Enhanced_Superior"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_60_PA",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wool",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "linen_shirt",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "toga",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "60",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_RP_15",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_900_L_Part1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_900_L_Part3",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_GoodsEach_900_L_Part2",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "75",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "400",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "450",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Package_Food_1",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "DawnAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "20000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "45000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "80000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "120000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "150000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "185000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "220000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "260000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "270000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good3_M",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "2100",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "20",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "350",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Customization_EventWorldFair2024_Home_Small",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "Reward_TeslaCrests_Common_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "Reward_TeslaCrests_Common_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_1"
                                                    },
                                                    "resource": "red_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_2"
                                                    },
                                                    "resource": "blue_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_3"
                                                    },
                                                    "resource": "green_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_4"
                                                    },
                                                    "resource": "yellow_crest_common",
                                                    "amount": "2"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_TeslaCrests_Common_2_5"
                                                    },
                                                    "resource": "purple_crest_common",
                                                    "amount": "2"
                                                }
                                            ]
                                        }
                                    ],
                                    "baseData": {
                                        "id": "Reward_TeslaCrests_Common_2"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Requirement_Good3_L",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "food",
                                    "amount": "4150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "300",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "750",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Coins_10",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "150",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "1000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "6000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "10000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "14000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "19500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "25000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "31500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "39000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "48500",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "coins",
                                    "amount": "60000",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_40",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "40",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_Reward_Goods_50",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 1,
                                    "amount": 40
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 2,
                                    "amount": 40
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "offset": 1,
                                    "number": 3,
                                    "amount": 40
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 55
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 55
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 55
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 90
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 90
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 90
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 110
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 130
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 130
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 130
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 130
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 135
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 135
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 135
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 140
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 140
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 145
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 2,
                                    "amount": 145
                                },
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 145
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good1_30",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 1,
                                    "amount": 30
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "stone_tablet",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "mosaic",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "planks",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cartwheel",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "barrel",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "door",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "wardrobe",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TreasureHunt_GroupRankReward_1_RP",
        "mapping": [
            {
                "@type": "type.googleapis.com/TreasureHuntAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "BronzeAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "10",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "12",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "15",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "18",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "22",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "24",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "28",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "30",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "34",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "research_points",
                                    "amount": "38",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_TreasureHunt_RR1_Wrapper",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_0",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_0_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_0_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_0_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_0_Reward_3"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_0_Reward_4"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "10"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_0"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_1",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_1_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_1_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_1_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_1_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "3000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_1_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "300"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_1_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "10"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_1"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_2",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_2_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_2_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_2_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_2_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "4000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_2_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_2_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "10"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_2"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_3",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_3_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_3_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_3_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_3_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "5500"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_3_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_3_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "15"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_3"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_4",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_4_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_4_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_4_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_4_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "7000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_4_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "700"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_4_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "15"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_4"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_5",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_5_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_5_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_5_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_5_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "8500"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_5_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "1500"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_5_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "15"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_5"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_6",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_6_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_6_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_6_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_6_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "10000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_6_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "1500"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_6_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "20"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_6"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_7",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_7_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_7_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_7_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_7_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "12000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_7_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "1500"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_7_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "20"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_7"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/RewardDefinitionDTO",
                                    "id": "reward.TreasureHunt_RR1_8",
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/LootContainerRewardDTO",
                                            "baseData": {
                                                "id": "TreasureHunt_RR1_8_Id"
                                            },
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_8_Reward_1"
                                                    },
                                                    "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                                                    "amount": "1"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_8_Reward_2"
                                                    },
                                                    "resource": "rift_token_common",
                                                    "amount": "3"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_8_Reward_3"
                                                    },
                                                    "resource": "resource.mastery_points",
                                                    "amount": "15000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_8_Reward_4"
                                                    },
                                                    "resource": "resource.hero_xp",
                                                    "amount": "3000"
                                                },
                                                {
                                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                                    "baseData": {
                                                        "id": "TreasureHunt_RR1_8_Reward_5"
                                                    },
                                                    "resource": "research_points",
                                                    "amount": "20"
                                                }
                                            ],
                                            "assetId": "icon_mystery_chest_gold"
                                        }
                                    ],
                                    "baseData": {
                                        "id": "reward.TreasureHunt_RR1_8"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ExtraProduction_GoodsEach_240",
        "mapping": [
            {
                "@type": "type.googleapis.com/PlayerAgeDynamicChangeDTO",
                "values": [
                    {
                        "when": "StoneAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good1",
                                    "amount": "24",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good2",
                                    "amount": "24",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|BronzeAge_Good3",
                                    "amount": "24",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "MinoanEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good1",
                                    "amount": "42",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good2",
                                    "amount": "42",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|MinoanEra_Good3",
                                    "amount": "42",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ClassicGreece",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good1",
                                    "amount": "78",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good2",
                                    "amount": "78",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ClassicGreece_Good3",
                                    "amount": "78",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "EarlyRome",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good1",
                                    "amount": "112",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good2",
                                    "amount": "112",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|EarlyRome_Good3",
                                    "amount": "112",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "RomanEmpire",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good1",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|RomanEmpire_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "ByzantineEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good1",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|ByzantineEra_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "AgeOfTheFranks",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good1",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|AgeOfTheFranks_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "FeudalAge",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good1",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|FeudalAge_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "IberianEra",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good1",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|IberianEra_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "KingdomOfSicily",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good1",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good2",
                                    "amount": "120",
                                    "fromResourceChange": true
                                },
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "DYN|KingdomOfSicily_Good3",
                                    "amount": "120",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        "@type": "type.googleapis.com/DynamicActionChangeDefinitionDTO",
        "id": "Dac_ATH_Good3_110",
        "mapping": [
            {
                "@type": "type.googleapis.com/HeroTreasureHuntDifficultyDynamicActionChangeDTO",
                "cases": [
                    {
                        "when": "0",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "1",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/GoodRewardDTO",
                                    "number": 3,
                                    "amount": 110
                                }
                            ]
                        }
                    },
                    {
                        "when": "2",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "tunic",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "3",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "cape",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "4",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "pepper",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "5",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "salt",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "6",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "herbs",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "7",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "saffron",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    },
                    {
                        "when": "8",
                        "then": {
                            "@type": "type.googleapis.com/ActionChangeDTO",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/ResourceRewardDTO",
                                    "baseData": {},
                                    "resource": "chili",
                                    "amount": "110",
                                    "fromResourceChange": true
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    }
]

export default dynamicDefinitions;