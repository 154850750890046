import Capital_Small_Home_Lv1 from "../../../images/buildings/intro/Capital_Small_Home_Lv1.webp";
import Capital_Small_Home_Lv2 from "../../../images/buildings/intro/Capital_Small_Home_Lv2.webp";
import Capital_Small_Home_Lv3 from "../../../images/buildings/intro/Capital_Small_Home_Lv3.webp";
import Capital_Small_Home_Lv4 from "../../../images/buildings/intro/Capital_Small_Home_Lv4.webp";
import Capital_Small_Home_Lv5 from "../../../images/buildings/intro/Capital_Small_Home_Lv5.webp";
import Capital_Small_Home_Lv6 from "../../../images/buildings/intro/Capital_Small_Home_Lv6.webp";
import Capital_Small_Home_Lv7 from "../../../images/buildings/intro/Capital_Small_Home_Lv7.webp";
import Capital_Small_Home_Lv8 from "../../../images/buildings/intro/Capital_Small_Home_Lv8.webp";
import Capital_Small_Home_Lv9 from "../../../images/buildings/intro/Capital_Small_Home_Lv9.webp";
import Capital_Small_Home_Lv10 from "../../../images/buildings/intro/Capital_Small_Home_Lv10.webp";
import Capital_Small_Home_Lv11 from "../../../images/buildings/intro/Capital_Small_Home_Lv11.webp";
import Capital_Small_Home_Lv12 from "../../../images/buildings/intro/Capital_Small_Home_Lv12.webp";
import Capital_Small_Home_Lv13 from "../../../images/buildings/intro/Capital_Small_Home_Lv13.webp";
import Capital_Small_Home_Lv14 from "../../../images/buildings/intro/Capital_Small_Home_Lv14.webp";
import Capital_Small_Home_Lv15 from "../../../images/buildings/intro/Capital_Small_Home_Lv15.webp";
import Capital_Small_Home_Lv16 from "../../../images/buildings/intro/Capital_Small_Home_Lv16.webp";
import Capital_Small_Home_Lv17 from "../../../images/buildings/intro/Capital_Small_Home_Lv17.webp";
import Capital_Small_Home_Lv18 from "../../../images/buildings/intro/Capital_Small_Home_Lv18.webp";
import Capital_Small_Home_Lv19 from "../../../images/buildings/intro/Capital_Small_Home_Lv19.webp";
import Capital_Small_Home_Lv20 from "../../../images/buildings/intro/Capital_Small_Home_Lv20.webp";
import Capital_Small_Home_Lv21 from "../../../images/buildings/intro/Capital_Small_Home_Lv21.webp";
import Capital_Small_Home_Lv22 from "../../../images/buildings/intro/Capital_Small_Home_Lv22.webp";
import Capital_Small_Home_Lv23 from "../../../images/buildings/intro/Capital_Small_Home_Lv23.webp";
import Capital_Small_Home_Lv24 from "../../../images/buildings/intro/Capital_Small_Home_Lv24.webp";
import Capital_Small_Home_Lv25 from "../../../images/buildings/intro/Capital_Small_Home_Lv25.webp";
import Capital_Small_Home_Lv26 from "../../../images/buildings/intro/Capital_Small_Home_Lv26.webp";
import Capital_Small_Home_Lv27 from "../../../images/buildings/intro/Capital_Small_Home_Lv27.webp";
import Capital_Small_Home_Lv28 from "../../../images/buildings/intro/Capital_Small_Home_Lv28.webp";
import Capital_Small_Home_Lv29 from "../../../images/buildings/intro/Capital_Small_Home_Lv29.webp";
import Capital_Small_Home_Lv30 from "../../../images/buildings/intro/Capital_Small_Home_Lv30.webp";
import Capital_Small_Home_Lv31 from "../../../images/buildings/intro/Capital_Small_Home_Lv31.webp";
import Capital_Small_Home_Lv32 from "../../../images/buildings/intro/Capital_Small_Home_Lv32.webp";
import Capital_Small_Home_Lv33 from "../../../images/buildings/intro/Capital_Small_Home_Lv33.webp";
import Capital_Average_Home_Lv4 from "../../../images/buildings/intro/Capital_Average_Home_Lv4.webp";
import Capital_Average_Home_Lv5 from "../../../images/buildings/intro/Capital_Average_Home_Lv5.webp";
import Capital_Average_Home_Lv6 from "../../../images/buildings/intro/Capital_Average_Home_Lv6.webp";
import Capital_Average_Home_Lv7 from "../../../images/buildings/intro/Capital_Average_Home_Lv7.webp";
import Capital_Average_Home_Lv8 from "../../../images/buildings/intro/Capital_Average_Home_Lv8.webp";
import Capital_Average_Home_Lv9 from "../../../images/buildings/intro/Capital_Average_Home_Lv9.webp";
import Capital_Average_Home_Lv10 from "../../../images/buildings/intro/Capital_Average_Home_Lv10.webp";
import Capital_Average_Home_Lv11 from "../../../images/buildings/intro/Capital_Average_Home_Lv11.webp";
import Capital_Average_Home_Lv12 from "../../../images/buildings/intro/Capital_Average_Home_Lv12.webp";
import Capital_Average_Home_Lv13 from "../../../images/buildings/intro/Capital_Average_Home_Lv13.webp";
import Capital_Average_Home_Lv14 from "../../../images/buildings/intro/Capital_Average_Home_Lv14.webp";
import Capital_Average_Home_Lv15 from "../../../images/buildings/intro/Capital_Average_Home_Lv15.webp";
import Capital_Average_Home_Lv16 from "../../../images/buildings/intro/Capital_Average_Home_Lv16.webp";
import Capital_Average_Home_Lv17 from "../../../images/buildings/intro/Capital_Average_Home_Lv17.webp";
import Capital_Average_Home_Lv18 from "../../../images/buildings/intro/Capital_Average_Home_Lv18.webp";
import Capital_Average_Home_Lv19 from "../../../images/buildings/intro/Capital_Average_Home_Lv19.webp";
import Capital_Average_Home_Lv20 from "../../../images/buildings/intro/Capital_Average_Home_Lv20.webp";
import Capital_Average_Home_Lv21 from "../../../images/buildings/intro/Capital_Average_Home_Lv21.webp";
import Capital_Average_Home_Lv22 from "../../../images/buildings/intro/Capital_Average_Home_Lv22.webp";
import Capital_Average_Home_Lv23 from "../../../images/buildings/intro/Capital_Average_Home_Lv23.webp";
import Capital_Average_Home_Lv24 from "../../../images/buildings/intro/Capital_Average_Home_Lv24.webp";
import Capital_Average_Home_Lv25 from "../../../images/buildings/intro/Capital_Average_Home_Lv25.webp";
import Capital_Average_Home_Lv26 from "../../../images/buildings/intro/Capital_Average_Home_Lv26.webp";
import Capital_Average_Home_Lv27 from "../../../images/buildings/intro/Capital_Average_Home_Lv27.webp";
import Capital_Average_Home_Lv28 from "../../../images/buildings/intro/Capital_Average_Home_Lv28.webp";
import Capital_Average_Home_Lv29 from "../../../images/buildings/intro/Capital_Average_Home_Lv29.webp";
import Capital_Average_Home_Lv30 from "../../../images/buildings/intro/Capital_Average_Home_Lv30.webp";
import Capital_Average_Home_Lv31 from "../../../images/buildings/intro/Capital_Average_Home_Lv31.webp";
import Capital_Average_Home_Lv32 from "../../../images/buildings/intro/Capital_Average_Home_Lv32.webp";
import Capital_Average_Home_Lv33 from "../../../images/buildings/intro/Capital_Average_Home_Lv33.webp";
import Capital_Luxurious_Home_Lv3 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv3.webp";
import Capital_Luxurious_Home_Lv6 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv6.webp";
import Capital_Luxurious_Home_Lv9 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv9.webp";
import Capital_Luxurious_Home_Lv12 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv12.webp";
import Capital_Luxurious_Home_Lv15 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv15.webp";
import Capital_Luxurious_Home_Lv18 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv18.webp";
import Capital_Luxurious_Home_Lv21 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv21.webp";
import Capital_Luxurious_Home_Lv24 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv24.webp";
import Capital_Luxurious_Home_Lv27 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv27.webp";
import Capital_Luxurious_Home_Lv30 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv30.webp";
import Capital_Luxurious_Home_Lv33 from "../../../images/buildings/intro/Capital_Luxurious_Home_Lv33.webp";
import Capital_Rural_Farm_Lv1 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv1.webp";
import Capital_Rural_Farm_Lv2 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv2.webp";
import Capital_Rural_Farm_Lv3 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv3.webp";
import Capital_Rural_Farm_Lv4 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv4.webp";
import Capital_Rural_Farm_Lv5 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv5.webp";
import Capital_Rural_Farm_Lv6 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv6.webp";
import Capital_Rural_Farm_Lv7 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv7.webp";
import Capital_Rural_Farm_Lv8 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv8.webp";
import Capital_Rural_Farm_Lv9 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv9.webp";
import Capital_Rural_Farm_Lv10 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv10.webp";
import Capital_Rural_Farm_Lv11 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv11.webp";
import Capital_Rural_Farm_Lv12 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv12.webp";
import Capital_Rural_Farm_Lv13 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv13.webp";
import Capital_Rural_Farm_Lv14 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv14.webp";
import Capital_Rural_Farm_Lv15 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv15.webp";
import Capital_Rural_Farm_Lv16 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv16.webp";
import Capital_Rural_Farm_Lv17 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv17.webp";
import Capital_Rural_Farm_Lv18 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv18.webp";
import Capital_Rural_Farm_Lv19 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv19.webp";
import Capital_Rural_Farm_Lv20 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv20.webp";
import Capital_Rural_Farm_Lv21 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv21.webp";
import Capital_Rural_Farm_Lv22 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv22.webp";
import Capital_Rural_Farm_Lv23 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv23.webp";
import Capital_Rural_Farm_Lv24 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv24.webp";
import Capital_Rural_Farm_Lv25 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv25.webp";
import Capital_Rural_Farm_Lv26 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv26.webp";
import Capital_Rural_Farm_Lv27 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv27.webp";
import Capital_Rural_Farm_Lv28 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv28.webp";
import Capital_Rural_Farm_Lv29 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv29.webp";
import Capital_Rural_Farm_Lv30 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv30.webp";
import Capital_Rural_Farm_Lv31 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv31.webp";
import Capital_Rural_Farm_Lv32 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv32.webp";
import Capital_Rural_Farm_Lv33 from "../../../images/buildings/intro/Capital_Rural_Farm_Lv33.webp";
import Capital_Domestic_Farm_Lv1 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv1.webp";
import Capital_Domestic_Farm_Lv2 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv2.webp";
import Capital_Domestic_Farm_Lv3 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv3.webp";
import Capital_Domestic_Farm_Lv4 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv4.webp";
import Capital_Domestic_Farm_Lv5 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv5.webp";
import Capital_Domestic_Farm_Lv6 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv6.webp";
import Capital_Domestic_Farm_Lv7 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv7.webp";
import Capital_Domestic_Farm_Lv8 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv8.webp";
import Capital_Domestic_Farm_Lv9 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv9.webp";
import Capital_Domestic_Farm_Lv10 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv10.webp";
import Capital_Domestic_Farm_Lv11 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv11.webp";
import Capital_Domestic_Farm_Lv12 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv12.webp";
import Capital_Domestic_Farm_Lv13 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv13.webp";
import Capital_Domestic_Farm_Lv14 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv14.webp";
import Capital_Domestic_Farm_Lv15 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv15.webp";
import Capital_Domestic_Farm_Lv16 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv16.webp";
import Capital_Domestic_Farm_Lv17 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv17.webp";
import Capital_Domestic_Farm_Lv18 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv18.webp";
import Capital_Domestic_Farm_Lv19 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv19.webp";
import Capital_Domestic_Farm_Lv20 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv20.webp";
import Capital_Domestic_Farm_Lv21 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv21.webp";
import Capital_Domestic_Farm_Lv22 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv22.webp";
import Capital_Domestic_Farm_Lv23 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv23.webp";
import Capital_Domestic_Farm_Lv24 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv24.webp";
import Capital_Domestic_Farm_Lv25 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv25.webp";
import Capital_Domestic_Farm_Lv26 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv26.webp";
import Capital_Domestic_Farm_Lv27 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv27.webp";
import Capital_Domestic_Farm_Lv28 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv28.webp";
import Capital_Domestic_Farm_Lv29 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv29.webp";
import Capital_Domestic_Farm_Lv30 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv30.webp";
import Capital_Domestic_Farm_Lv31 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv31.webp";
import Capital_Domestic_Farm_Lv32 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv32.webp";
import Capital_Domestic_Farm_Lv33 from "../../../images/buildings/intro/Capital_Domestic_Farm_Lv33.webp";
import Capital_Luxurious_Farm_Lv3 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv3.webp";
import Capital_Luxurious_Farm_Lv6 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv6.webp";
import Capital_Luxurious_Farm_Lv9 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv9.webp";
import Capital_Luxurious_Farm_Lv12 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv12.webp";
import Capital_Luxurious_Farm_Lv15 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv15.webp";
import Capital_Luxurious_Farm_Lv18 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv18.webp";
import Capital_Luxurious_Farm_Lv21 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv21.webp";
import Capital_Luxurious_Farm_Lv24 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv24.webp";
import Capital_Luxurious_Farm_Lv27 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv27.webp";
import Capital_Luxurious_Farm_Lv30 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv30.webp";
import Capital_Luxurious_Farm_Lv33 from "../../../images/buildings/intro/Capital_Luxurious_Farm_Lv33.webp";
import Capital_Moderate_Culture_Site_Lv1 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv1.webp";
import Capital_Moderate_Culture_Site_Lv2 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv2.webp";
import Capital_Moderate_Culture_Site_Lv3 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv3.webp";
import Capital_Moderate_Culture_Site_Lv4 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv4.webp";
import Capital_Moderate_Culture_Site_Lv5 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv5.webp";
import Capital_Moderate_Culture_Site_Lv6 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv6.webp";
import Capital_Moderate_Culture_Site_Lv7 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv7.webp";
import Capital_Moderate_Culture_Site_Lv8 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv8.webp";
import Capital_Moderate_Culture_Site_Lv9 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv9.webp";
import Capital_Moderate_Culture_Site_Lv10 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv10.webp";
import Capital_Moderate_Culture_Site_Lv11 from "../../../images/buildings/intro/Capital_Moderate_Culture_Site_Lv11.webp";
import Capital_Compact_Culture_Site_Lv1 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv1.webp";
import Capital_Compact_Culture_Site_Lv2 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv2.webp";
import Capital_Compact_Culture_Site_Lv3 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv3.webp";
import Capital_Compact_Culture_Site_Lv4 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv4.webp";
import Capital_Compact_Culture_Site_Lv5 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv5.webp";
import Capital_Compact_Culture_Site_Lv6 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv6.webp";
import Capital_Compact_Culture_Site_Lv7 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv7.webp";
import Capital_Compact_Culture_Site_Lv8 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv8.webp";
import Capital_Compact_Culture_Site_Lv9 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv9.webp";
import Capital_Compact_Culture_Site_Lv10 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv10.webp";
import Capital_Compact_Culture_Site_Lv11 from "../../../images/buildings/intro/Capital_Compact_Culture_Site_Lv11.webp";
import Capital_Little_Culture_Site_Lv2 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv2.webp";
import Capital_Little_Culture_Site_Lv3 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv3.webp";
import Capital_Little_Culture_Site_Lv4 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv4.webp";
import Capital_Little_Culture_Site_Lv5 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv5.webp";
import Capital_Little_Culture_Site_Lv6 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv6.webp";
import Capital_Little_Culture_Site_Lv7 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv7.webp";
import Capital_Little_Culture_Site_Lv8 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv8.webp";
import Capital_Little_Culture_Site_Lv9 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv9.webp";
import Capital_Little_Culture_Site_Lv10 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv10.webp";
import Capital_Little_Culture_Site_Lv11 from "../../../images/buildings/intro/Capital_Little_Culture_Site_Lv11.webp";
import Capital_Artisan_Lv1 from "../../../images/buildings/intro/Capital_Artisan_Lv1.webp";
import Capital_Artisan_Lv2 from "../../../images/buildings/intro/Capital_Artisan_Lv2.webp";
import Capital_Artisan_Lv3 from "../../../images/buildings/intro/Capital_Artisan_Lv3.webp";
import Capital_Artisan_Lv4 from "../../../images/buildings/intro/Capital_Artisan_Lv4.webp";
import Capital_Artisan_Lv5 from "../../../images/buildings/intro/Capital_Artisan_Lv5.webp";
import Capital_Stone_Mason_Lv1 from "../../../images/buildings/intro/Capital_Stone_Mason_Lv1.webp";
import Capital_Stone_Mason_Lv2 from "../../../images/buildings/intro/Capital_Stone_Mason_Lv2.webp";
import Capital_Stone_Mason_Lv3 from "../../../images/buildings/intro/Capital_Stone_Mason_Lv3.webp";
import Capital_Stone_Mason_Lv4 from "../../../images/buildings/intro/Capital_Stone_Mason_Lv4.webp";
import Capital_Stone_Mason_Lv5 from "../../../images/buildings/intro/Capital_Stone_Mason_Lv5.webp";
import Capital_Tailor_Lv1 from "../../../images/buildings/intro/Capital_Tailor_Lv1.webp";
import Capital_Tailor_Lv2 from "../../../images/buildings/intro/Capital_Tailor_Lv2.webp";
import Capital_Tailor_Lv3 from "../../../images/buildings/intro/Capital_Tailor_Lv3.webp";
import Capital_Tailor_Lv4 from "../../../images/buildings/intro/Capital_Tailor_Lv4.webp";
import Capital_Tailor_Lv5 from "../../../images/buildings/intro/Capital_Tailor_Lv5.webp";
import Capital_Carpenter_Lv1 from "../../../images/buildings/intro/Capital_Carpenter_Lv1.webp";
import Capital_Carpenter_Lv2 from "../../../images/buildings/intro/Capital_Carpenter_Lv2.webp";
import Capital_Carpenter_Lv3 from "../../../images/buildings/intro/Capital_Carpenter_Lv3.webp";
import Capital_Carpenter_Lv4 from "../../../images/buildings/intro/Capital_Carpenter_Lv4.webp";
import Capital_Carpenter_Lv5 from "../../../images/buildings/intro/Capital_Carpenter_Lv5.webp";
import Capital_Scribe_Lv1 from "../../../images/buildings/intro/Capital_Scribe_Lv1.webp";
import Capital_Scribe_Lv2 from "../../../images/buildings/intro/Capital_Scribe_Lv2.webp";
import Capital_Scribe_Lv3 from "../../../images/buildings/intro/Capital_Scribe_Lv3.webp";
import Capital_Scribe_Lv4 from "../../../images/buildings/intro/Capital_Scribe_Lv4.webp";
import Capital_Scribe_Lv5 from "../../../images/buildings/intro/Capital_Scribe_Lv5.webp";
import Capital_Spice_Merchant_Lv1 from "../../../images/buildings/intro/Capital_Spice_Merchant_Lv1.webp";
import Capital_Spice_Merchant_Lv2 from "../../../images/buildings/intro/Capital_Spice_Merchant_Lv2.webp";
import Capital_Spice_Merchant_Lv3 from "../../../images/buildings/intro/Capital_Spice_Merchant_Lv3.webp";
import Capital_Spice_Merchant_Lv4 from "../../../images/buildings/intro/Capital_Spice_Merchant_Lv4.webp";
import Capital_Spice_Merchant_Lv5 from "../../../images/buildings/intro/Capital_Spice_Merchant_Lv5.webp";
import Capital_Infantry_Barracks_Lv1 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv1.webp";
import Capital_Infantry_Barracks_Lv2 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv2.webp";
import Capital_Infantry_Barracks_Lv3 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv3.webp";
import Capital_Infantry_Barracks_Lv4 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv4.webp";
import Capital_Infantry_Barracks_Lv5 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv5.webp";
import Capital_Infantry_Barracks_Lv6 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv6.webp";
import Capital_Infantry_Barracks_Lv7 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv7.webp";
import Capital_Infantry_Barracks_Lv8 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv8.webp";
import Capital_Infantry_Barracks_Lv9 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv9.webp";
import Capital_Infantry_Barracks_Lv10 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv10.webp";
import Capital_Infantry_Barracks_Lv11 from "../../../images/buildings/intro/Capital_Infantry_Barracks_Lv11.webp";
import Capital_Ranged_Barracks_Lv1 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv1.webp";
import Capital_Ranged_Barracks_Lv2 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv2.webp";
import Capital_Ranged_Barracks_Lv3 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv3.webp";
import Capital_Ranged_Barracks_Lv4 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv4.webp";
import Capital_Ranged_Barracks_Lv5 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv5.webp";
import Capital_Ranged_Barracks_Lv6 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv6.webp";
import Capital_Ranged_Barracks_Lv7 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv7.webp";
import Capital_Ranged_Barracks_Lv8 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv8.webp";
import Capital_Ranged_Barracks_Lv9 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv9.webp";
import Capital_Ranged_Barracks_Lv10 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv10.webp";
import Capital_Ranged_Barracks_Lv11 from "../../../images/buildings/intro/Capital_Ranged_Barracks_Lv11.webp";
import Capital_Cavalry_Barracks_Lv2 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv2.webp";
import Capital_Cavalry_Barracks_Lv3 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv3.webp";
import Capital_Cavalry_Barracks_Lv4 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv4.webp";
import Capital_Cavalry_Barracks_Lv5 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv5.webp";
import Capital_Cavalry_Barracks_Lv6 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv6.webp";
import Capital_Cavalry_Barracks_Lv7 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv7.webp";
import Capital_Cavalry_Barracks_Lv8 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv8.webp";
import Capital_Cavalry_Barracks_Lv9 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv9.webp";
import Capital_Cavalry_Barracks_Lv10 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv10.webp";
import Capital_Cavalry_Barracks_Lv11 from "../../../images/buildings/intro/Capital_Cavalry_Barracks_Lv11.webp";
import Capital_Heavy_Infantry_Barracks_Lv4 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv4.webp";
import Capital_Heavy_Infantry_Barracks_Lv5 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv5.webp";
import Capital_Heavy_Infantry_Barracks_Lv6 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv6.webp";
import Capital_Heavy_Infantry_Barracks_Lv7 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv7.webp";
import Capital_Heavy_Infantry_Barracks_Lv8 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv8.webp";
import Capital_Heavy_Infantry_Barracks_Lv9 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv9.webp";
import Capital_Heavy_Infantry_Barracks_Lv10 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv10.webp";
import Capital_Heavy_Infantry_Barracks_Lv11 from "../../../images/buildings/intro/Capital_Heavy_Infantry_Barracks_Lv11.webp";
import Capital_Siege_Barracks_Lv6 from "../../../images/buildings/intro/Capital_Siege_Barracks_Lv6.webp";
import Capital_Siege_Barracks_Lv7 from "../../../images/buildings/intro/Capital_Siege_Barracks_Lv7.webp";
import Capital_Siege_Barracks_Lv8 from "../../../images/buildings/intro/Capital_Siege_Barracks_Lv8.webp";
import Capital_Siege_Barracks_Lv9 from "../../../images/buildings/intro/Capital_Siege_Barracks_Lv9.webp";
import Capital_Siege_Barracks_Lv10 from "../../../images/buildings/intro/Capital_Siege_Barracks_Lv10.webp";
import Capital_Siege_Barracks_Lv11 from "../../../images/buildings/intro/Capital_Siege_Barracks_Lv11.webp";

const stoneAgeBuildings = {
    "Small Home": {
        "Building_StoneAge_Home_Small_1": Capital_Small_Home_Lv1,
        "Building_StoneAge_Home_Small_2": Capital_Small_Home_Lv2,
        "Building_StoneAge_Home_Small_3": Capital_Small_Home_Lv3,
    },
    "Luxurious Home": {
        "Building_StoneAge_Home_Premium_1": Capital_Luxurious_Home_Lv3,
    },
    "Rural Farm": {
        "Building_StoneAge_Farm_Rural_1": Capital_Rural_Farm_Lv1,
        "Building_StoneAge_Farm_Rural_2": Capital_Rural_Farm_Lv2,
        "Building_StoneAge_Farm_Rural_3": Capital_Rural_Farm_Lv3,
    },
    "Domestic Farm": {
        "Building_StoneAge_Farm_Domestic_1": Capital_Domestic_Farm_Lv1,
        "Building_StoneAge_Farm_Domestic_2": Capital_Domestic_Farm_Lv2,
        "Building_StoneAge_Farm_Domestic_3": Capital_Domestic_Farm_Lv3,
    },
    "Luxurious Farm": {
        "Building_StoneAge_Farm_Premium_1": Capital_Luxurious_Farm_Lv3,
    },
    "Moderate Culture Site": {
        "Building_StoneAge_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv1,
    },
    "Compact Culture Site": {
        "Building_StoneAge_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv1,
    },
    "Infantry Barracks": {
        "Building_StoneAge_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv1,
    },
    "Ranged Barracks": {
        "Building_StoneAge_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv1,
    },
    "Cavalry Barracks": {
        "Building_StoneAge_Barracks_Cavalry_1": "",
    },
}

const bronzeAgeBuildings = {
    "Small Home": {
        "Building_BronzeAge_Home_Small_1": Capital_Small_Home_Lv4,
        "Building_BronzeAge_Home_Small_2": Capital_Small_Home_Lv5,
        "Building_BronzeAge_Home_Small_3": Capital_Small_Home_Lv6,
    },
    "Medium Home": {
        "Building_BronzeAge_Home_Average_1": Capital_Average_Home_Lv4,
        "Building_BronzeAge_Home_Average_2": Capital_Average_Home_Lv5,
        "Building_BronzeAge_Home_Average_3": Capital_Average_Home_Lv6,
    },
    "Luxurious Home": {
        "Building_BronzeAge_Home_Premium_1": Capital_Luxurious_Home_Lv6,
    },
    "Rural Farm": {
        "Building_BronzeAge_Farm_Rural_1": Capital_Rural_Farm_Lv4,
        "Building_BronzeAge_Farm_Rural_2": Capital_Rural_Farm_Lv5,
        "Building_BronzeAge_Farm_Rural_3": Capital_Rural_Farm_Lv6,
    },
    "Domestic Farm": {
        "Building_BronzeAge_Farm_Domestic_1": Capital_Domestic_Farm_Lv4,
        "Building_BronzeAge_Farm_Domestic_2": Capital_Domestic_Farm_Lv5,
        "Building_BronzeAge_Farm_Domestic_3": Capital_Domestic_Farm_Lv6,
    },
    "Luxurious Farm": {
        "Building_BronzeAge_Farm_Premium_1": Capital_Luxurious_Farm_Lv6,
    },
    "Moderate Culture Site": {
        "Building_BronzeAge_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv2,
    },
    "Compact Culture Site": {
        "Building_BronzeAge_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv2,
    },
    "Little Culture Site": {
        "Building_BronzeAge_CultureSite_Little_1": Capital_Little_Culture_Site_Lv2,
    },
    "Stone Mason": {
        "Building_BronzeAge_Workshop_StoneMason_1": Capital_Stone_Mason_Lv1,
    },
    "Artisan": {
        "Building_BronzeAge_Workshop_Artisan_1": Capital_Artisan_Lv1,
    },
    "Tailor": {
        "Building_BronzeAge_Workshop_Tailor_1": Capital_Tailor_Lv1,
    },
    "Infantry Barracks": {
        "Building_BronzeAge_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv2,
        "Building_BronzeAge_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv2,
        "Building_BronzeAge_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv2,
    },
    "Ranged Barracks": {
        "Building_BronzeAge_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv2,
        "Building_BronzeAge_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv2,
        "Building_BronzeAge_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv2,
    },
    "Cavalry Barracks": {
        "Building_BronzeAge_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv2,
        "Building_BronzeAge_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv2,
        "Building_BronzeAge_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv2,
    },
    "Heavy Infantry Barracks": {
        "Building_BronzeAge_Barracks_HeavyInfantry_1": "",
        "Building_BronzeAge_Barracks_HeavyInfantry_2": "",
        "Building_BronzeAge_Barracks_HeavyInfantry_3": "",
    },
    "Siege Barracks": {
        "Building_BronzeAge_Barracks_Siege_1": "",
        "Building_BronzeAge_Barracks_Siege_2": "",
        "Building_BronzeAge_Barracks_Siege_3": "",
    },
}

const minoanEraBuildings = {
    "Small Home": {
        "Building_MinoanEra_Home_Small_1": Capital_Small_Home_Lv7,
        "Building_MinoanEra_Home_Small_2": Capital_Small_Home_Lv8,
        "Building_MinoanEra_Home_Small_3": Capital_Small_Home_Lv9,
    },
    "Medium Home": {
        "Building_MinoanEra_Home_Average_1": Capital_Average_Home_Lv7,
        "Building_MinoanEra_Home_Average_2": Capital_Average_Home_Lv8,
        "Building_MinoanEra_Home_Average_3": Capital_Average_Home_Lv9,
    },
    "Luxurious Home": {
        "Building_MinoanEra_Home_Premium_1": Capital_Luxurious_Home_Lv9,
    },
    "Rural Farm": {
        "Building_MinoanEra_Farm_Rural_1": Capital_Rural_Farm_Lv7,
        "Building_MinoanEra_Farm_Rural_2": Capital_Rural_Farm_Lv8,
        "Building_MinoanEra_Farm_Rural_3": Capital_Rural_Farm_Lv9,
    },
    "Domestic Farm": {
        "Building_MinoanEra_Farm_Domestic_1": Capital_Domestic_Farm_Lv7,
        "Building_MinoanEra_Farm_Domestic_2": Capital_Domestic_Farm_Lv8,
        "Building_MinoanEra_Farm_Domestic_3": Capital_Domestic_Farm_Lv9,
    },
    "Luxurious Farm": {
        "Building_MinoanEra_Farm_Premium_1": Capital_Luxurious_Farm_Lv9,
    },
    "Moderate Culture Site": {
        "Building_MinoanEra_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv3,
    },
    "Compact Culture Site": {
        "Building_MinoanEra_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv3,
    },
    "Little Culture Site": {
        "Building_MinoanEra_CultureSite_Little_1": Capital_Little_Culture_Site_Lv3,
    },
    "Stone Mason": {
        "Building_MinoanEra_Workshop_StoneMason_1": Capital_Stone_Mason_Lv2,
    },
    "Artisan": {
        "Building_MinoanEra_Workshop_Artisan_1": Capital_Artisan_Lv2,
    },
    "Tailor": {
        "Building_MinoanEra_Workshop_Tailor_1": Capital_Tailor_Lv2,
    },
    "Infantry Barracks": {
        "Building_MinoanEra_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv3,
        "Building_MinoanEra_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv3,
        "Building_MinoanEra_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv3,
    },
    "Ranged Barracks": {
        "Building_MinoanEra_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv3,
        "Building_MinoanEra_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv3,
        "Building_MinoanEra_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv3,
    },
    "Cavalry Barracks": {
        "Building_MinoanEra_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv3,
        "Building_MinoanEra_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv3,
        "Building_MinoanEra_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv3,
    },
    "Heavy Infantry Barracks": {
        "Building_MinoanEra_Barracks_HeavyInfantry_1": "",
        "Building_MinoanEra_Barracks_HeavyInfantry_2": "",
        "Building_MinoanEra_Barracks_HeavyInfantry_3": "",
    },
    "Siege Barracks": {
        "Building_MinoanEra_Barracks_Siege_1": "",
        "Building_MinoanEra_Barracks_Siege_2": "",
        "Building_MinoanEra_Barracks_Siege_3": "",
    },
    "Hero Academy": {
        "Building_MinoanEra_Special_HeroAcademy_1": "",
    },
}

const classicGreeceBuildings = {
    "Small Home": {
        "Building_ClassicGreece_Home_Small_1": Capital_Small_Home_Lv10,
        "Building_ClassicGreece_Home_Small_2": Capital_Small_Home_Lv11,
        "Building_ClassicGreece_Home_Small_3": Capital_Small_Home_Lv12,
    },
    "Medium Home": {
        "Building_ClassicGreece_Home_Average_1": Capital_Average_Home_Lv10,
        "Building_ClassicGreece_Home_Average_2": Capital_Average_Home_Lv11,
        "Building_ClassicGreece_Home_Average_3": Capital_Average_Home_Lv12,
    },
    "Luxurious Home": {
        "Building_ClassicGreece_Home_Premium_1": Capital_Luxurious_Home_Lv12,
    },
    "Rural Farm": {
        "Building_ClassicGreece_Farm_Rural_1": Capital_Rural_Farm_Lv10,
        "Building_ClassicGreece_Farm_Rural_2": Capital_Rural_Farm_Lv11,
        "Building_ClassicGreece_Farm_Rural_3": Capital_Rural_Farm_Lv12,
    },
    "Domestic Farm": {
        "Building_ClassicGreece_Farm_Domestic_1": Capital_Domestic_Farm_Lv10,
        "Building_ClassicGreece_Farm_Domestic_2": Capital_Domestic_Farm_Lv11,
        "Building_ClassicGreece_Farm_Domestic_3": Capital_Domestic_Farm_Lv12,
    },
    "Luxurious Farm": {
        "Building_ClassicGreece_Farm_Premium_1": Capital_Luxurious_Farm_Lv12,
    },
    "Moderate Culture Site": {
        "Building_ClassicGreece_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv4,
    },
    "Compact Culture Site": {
        "Building_ClassicGreece_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv4,
    },
    "Little Culture Site": {
        "Building_ClassicGreece_CultureSite_Little_1": Capital_Little_Culture_Site_Lv4,
    },
    "Stone Mason": {
        "Building_ClassicGreece_Workshop_StoneMason_1": Capital_Stone_Mason_Lv3,
    },
    "Artisan": {
        "Building_ClassicGreece_Workshop_Artisan_1": Capital_Artisan_Lv3,
    },
    "Tailor": {
        "Building_ClassicGreece_Workshop_Tailor_1": Capital_Tailor_Lv3,
    },
    "Infantry Barracks": {
        "Building_ClassicGreece_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv4,
    },
    "Ranged Barracks": {
        "Building_ClassicGreece_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv4,
    },
    "Cavalry Barracks": {
        "Building_ClassicGreece_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv4,
    },
    "Heavy Infantry Barracks": {
        "Building_ClassicGreece_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv4,
        "Building_ClassicGreece_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv4,
    },
    "Siege Barracks": {
        "Building_ClassicGreece_Barracks_Siege_1": "",
        "Building_ClassicGreece_Barracks_Siege_2": "",
        "Building_ClassicGreece_Barracks_Siege_3": "",
    },
    "Hero Academy": {
        "Building_ClassicGreece_Special_HeroAcademy_1": "",
    },
}

const earlyRomeBuildings = {
    "Small Home": {
        "Building_EarlyRome_Home_Small_1": Capital_Small_Home_Lv13,
        "Building_EarlyRome_Home_Small_2": Capital_Small_Home_Lv14,
        "Building_EarlyRome_Home_Small_3": Capital_Small_Home_Lv15,
    },
    "Medium Home": {
        "Building_EarlyRome_Home_Average_1": Capital_Average_Home_Lv13,
        "Building_EarlyRome_Home_Average_2": Capital_Average_Home_Lv14,
        "Building_EarlyRome_Home_Average_3": Capital_Average_Home_Lv15,
    },
    "Luxurious Home": {
        "Building_EarlyRome_Home_Premium_1": Capital_Luxurious_Home_Lv15,
    },
    "Rural Farm": {
        "Building_EarlyRome_Farm_Rural_1": Capital_Rural_Farm_Lv13,
        "Building_EarlyRome_Farm_Rural_2": Capital_Rural_Farm_Lv14,
        "Building_EarlyRome_Farm_Rural_3": Capital_Rural_Farm_Lv15,
    },
    "Domestic Farm": {
        "Building_EarlyRome_Farm_Domestic_1": Capital_Domestic_Farm_Lv13,
        "Building_EarlyRome_Farm_Domestic_2": Capital_Domestic_Farm_Lv14,
        "Building_EarlyRome_Farm_Domestic_3": Capital_Domestic_Farm_Lv15,
    },
    "Luxurious Farm": {
        "Building_EarlyRome_Farm_Premium_1": Capital_Luxurious_Farm_Lv15,
    },
    "Moderate Culture Site": {
        "Building_EarlyRome_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv5,
    },
    "Compact Culture Site": {
        "Building_EarlyRome_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv5,
    },
    "Little Culture Site": {
        "Building_EarlyRome_CultureSite_Little_1": Capital_Little_Culture_Site_Lv5,
    },
    "Stone Mason": {
        "Building_EarlyRome_Workshop_StoneMason_1": Capital_Stone_Mason_Lv4,
    },
    "Artisan": {
        "Building_EarlyRome_Workshop_Artisan_1": Capital_Artisan_Lv4,
    },
    "Tailor": {
        "Building_EarlyRome_Workshop_Tailor_1": Capital_Tailor_Lv4,
    },
    "Infantry Barracks": {
        "Building_EarlyRome_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv5,
        "Building_EarlyRome_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv5,
        "Building_EarlyRome_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv5,
    },
    "Ranged Barracks": {
        "Building_EarlyRome_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv5,
        "Building_EarlyRome_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv5,
        "Building_EarlyRome_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv5,
    },
    "Cavalry Barracks": {
        "Building_EarlyRome_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv5,
        "Building_EarlyRome_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv5,
        "Building_EarlyRome_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv5,
    },
    "Heavy Infantry Barracks": {
        "Building_EarlyRome_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv5,
        "Building_EarlyRome_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv5,
        "Building_EarlyRome_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv5,
    },
    "Siege Barracks": {
        "Building_EarlyRome_Barracks_Siege_1": "",
        "Building_EarlyRome_Barracks_Siege_2": "",
        "Building_EarlyRome_Barracks_Siege_3": "",
    },
    "Hero Academy": {
        "Building_EarlyRome_Special_HeroAcademy_1": "",
    },
}

const romanEmpireBuildings = {
    "Small Home": {
        "Building_RomanEmpire_Home_Small_1": Capital_Small_Home_Lv16,
        "Building_RomanEmpire_Home_Small_2": Capital_Small_Home_Lv17,
        "Building_RomanEmpire_Home_Small_3": Capital_Small_Home_Lv18,
    },
    "Medium Home": {
        "Building_RomanEmpire_Home_Average_1": Capital_Average_Home_Lv16,
        "Building_RomanEmpire_Home_Average_2": Capital_Average_Home_Lv17,
        "Building_RomanEmpire_Home_Average_3": Capital_Average_Home_Lv18,
    },
    "Luxurious Home": {
        "Building_RomanEmpire_Home_Premium_1": Capital_Luxurious_Home_Lv18,
    },
    "Rural Farm": {
        "Building_RomanEmpire_Farm_Rural_1": Capital_Rural_Farm_Lv16,
        "Building_RomanEmpire_Farm_Rural_2": Capital_Rural_Farm_Lv17,
        "Building_RomanEmpire_Farm_Rural_3": Capital_Rural_Farm_Lv18,
    },
    "Domestic Farm": {
        "Building_RomanEmpire_Farm_Domestic_1": Capital_Domestic_Farm_Lv16,
        "Building_RomanEmpire_Farm_Domestic_2": Capital_Domestic_Farm_Lv17,
        "Building_RomanEmpire_Farm_Domestic_3": Capital_Domestic_Farm_Lv18,
    },
    "Luxurious Farm": {
        "Building_RomanEmpire_Farm_Premium_1": Capital_Luxurious_Farm_Lv18,
    },
    "Moderate Culture Site": {
        "Building_RomanEmpire_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv6,
    },
    "Compact Culture Site": {
        "Building_RomanEmpire_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv6,
    },
    "Little Culture Site": {
        "Building_RomanEmpire_CultureSite_Little_1": Capital_Little_Culture_Site_Lv6,
    },
    "Stone Mason": {
        "Building_RomanEmpire_Workshop_StoneMason_1": Capital_Stone_Mason_Lv5,
    },
    "Artisan": {
        "Building_RomanEmpire_Workshop_Artisan_1": Capital_Artisan_Lv5,
    },
    "Tailor": {
        "Building_RomanEmpire_Workshop_Tailor_1": Capital_Tailor_Lv5,
    },
    "Infantry Barracks": {
        "Building_RomanEmpire_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv6,
    },
    "Ranged Barracks": {
        "Building_RomanEmpire_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv6,
    },
    "Cavalry Barracks": {
        "Building_RomanEmpire_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv6,
    },
    "Heavy Infantry Barracks": {
        "Building_RomanEmpire_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv6,
    },
    "Siege Barracks": {
        "Building_RomanEmpire_Barracks_Siege_1": Capital_Siege_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Siege_2": Capital_Siege_Barracks_Lv6,
        "Building_RomanEmpire_Barracks_Siege_3": Capital_Siege_Barracks_Lv6,
    },
    "Hero Academy": {
        "Building_RomanEmpire_Special_HeroAcademy_1": "",
    },
}

const byzantineEraBuildings = {
    "Small Home": {
        "Building_ByzantineEra_Home_Small_1": Capital_Small_Home_Lv19,
        "Building_ByzantineEra_Home_Small_2": Capital_Small_Home_Lv20,
        "Building_ByzantineEra_Home_Small_3": Capital_Small_Home_Lv21,
    },
    "Medium Home": {
        "Building_ByzantineEra_Home_Average_1": Capital_Average_Home_Lv19,
        "Building_ByzantineEra_Home_Average_2": Capital_Average_Home_Lv20,
        "Building_ByzantineEra_Home_Average_3": Capital_Average_Home_Lv21,
    },
    "Luxurious Home": {
        "Building_ByzantineEra_Home_Premium_1": Capital_Luxurious_Home_Lv21,
    },
    "Rural Farm": {
        "Building_ByzantineEra_Farm_Rural_1": Capital_Rural_Farm_Lv19,
        "Building_ByzantineEra_Farm_Rural_2": Capital_Rural_Farm_Lv20,
        "Building_ByzantineEra_Farm_Rural_3": Capital_Rural_Farm_Lv21,
    },
    "Domestic Farm": {
        "Building_ByzantineEra_Farm_Domestic_1": Capital_Domestic_Farm_Lv19,
        "Building_ByzantineEra_Farm_Domestic_2": Capital_Domestic_Farm_Lv20,
        "Building_ByzantineEra_Farm_Domestic_3": Capital_Domestic_Farm_Lv21,
    },
    "Luxurious Farm": {
        "Building_ByzantineEra_Farm_Premium_1": Capital_Luxurious_Farm_Lv21,
    },
    "Moderate Culture Site": {
        "Building_ByzantineEra_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv7,
    },
    "Compact Culture Site": {
        "Building_ByzantineEra_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv7,
    },
    "Little Culture Site": {
        "Building_ByzantineEra_CultureSite_Little_1": Capital_Little_Culture_Site_Lv7,
    },
    "Carpenter": {
        "Building_ByzantineEra_Workshop_Carpenter_1": Capital_Carpenter_Lv1,
    },
    "Scribe": {
        "Building_ByzantineEra_Workshop_Scribe_1": Capital_Scribe_Lv1,
    },
    "Spice Merchant": {
        "Building_ByzantineEra_Workshop_SpiceMerchant_1": Capital_Spice_Merchant_Lv1,
    },
    "Infantry Barracks": {
        "Building_ByzantineEra_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv7,
    },
    "Ranged Barracks": {
        "Building_ByzantineEra_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv7,
    },
    "Cavalry Barracks": {
        "Building_ByzantineEra_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv7,
    },
    "Heavy Infantry Barracks": {
        "Building_ByzantineEra_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv7,
    },
    "Siege Barracks": {
        "Building_ByzantineEra_Barracks_Siege_1": Capital_Siege_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Siege_2": Capital_Siege_Barracks_Lv7,
        "Building_ByzantineEra_Barracks_Siege_3": Capital_Siege_Barracks_Lv7,
    },
    "Hero Academy": {
        "Building_ByzantineEra_Special_HeroAcademy_1": "",
    },
}

const ageOfTheFranksBuildings = {
    "Small Home": {
        "Building_AgeOfTheFranks_Home_Small_1": Capital_Small_Home_Lv22,
        "Building_AgeOfTheFranks_Home_Small_2": Capital_Small_Home_Lv23,
        "Building_AgeOfTheFranks_Home_Small_3": Capital_Small_Home_Lv24,
    },
    "Medium Home": {
        "Building_AgeOfTheFranks_Home_Average_1": Capital_Average_Home_Lv22,
        "Building_AgeOfTheFranks_Home_Average_2": Capital_Average_Home_Lv23,
        "Building_AgeOfTheFranks_Home_Average_3": Capital_Average_Home_Lv24,
    },
    "Luxurious Home": {
        "Building_AgeOfTheFranks_Home_Premium_1": Capital_Luxurious_Home_Lv24,
    },
    "Rural Farm": {
        "Building_AgeOfTheFranks_Farm_Rural_1": Capital_Rural_Farm_Lv22,
        "Building_AgeOfTheFranks_Farm_Rural_2": Capital_Rural_Farm_Lv23,
        "Building_AgeOfTheFranks_Farm_Rural_3": Capital_Rural_Farm_Lv24,
    },
    "Domestic Farm": {
        "Building_AgeOfTheFranks_Farm_Domestic_1": Capital_Domestic_Farm_Lv22,
        "Building_AgeOfTheFranks_Farm_Domestic_2": Capital_Domestic_Farm_Lv23,
        "Building_AgeOfTheFranks_Farm_Domestic_3": Capital_Domestic_Farm_Lv24,
    },
    "Luxurious Farm": {
        "Building_AgeOfTheFranks_Farm_Premium_1": Capital_Luxurious_Farm_Lv24,
    },
    "Moderate Culture Site": {
        "Building_AgeOfTheFranks_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv8,
    },
    "Compact Culture Site": {
        "Building_AgeOfTheFranks_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv8,
    },
    "Little Culture Site": {
        "Building_AgeOfTheFranks_CultureSite_Little_1": Capital_Little_Culture_Site_Lv8,
    },
    "Carpenter": {
        "Building_AgeOfTheFranks_Workshop_Carpenter_1": Capital_Carpenter_Lv2,
    },
    "Scribe": {
        "Building_AgeOfTheFranks_Workshop_Scribe_1": Capital_Scribe_Lv2,
    },
    "Spice Merchant": {
        "Building_AgeOfTheFranks_Workshop_SpiceMerchant_1": Capital_Spice_Merchant_Lv2,
    },
    "Infantry Barracks": {
        "Building_AgeOfTheFranks_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv8,
    },
    "Ranged Barracks": {
        "Building_AgeOfTheFranks_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv8,
    },
    "Cavalry Barracks": {
        "Building_AgeOfTheFranks_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv8,
    },
    "Heavy Infantry Barracks": {
        "Building_AgeOfTheFranks_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv8,
    },
    "Siege Barracks": {
        "Building_AgeOfTheFranks_Barracks_Siege_1": Capital_Siege_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Siege_2": Capital_Siege_Barracks_Lv8,
        "Building_AgeOfTheFranks_Barracks_Siege_3": Capital_Siege_Barracks_Lv8,
    },
    "Hero Academy": {
        "Building_AgeOfTheFranks_Special_HeroAcademy_1": "",
    },
}

const feudalAgeBuildings = {
    "Small Home": {
        "Building_FeudalAge_Home_Small_1": Capital_Small_Home_Lv25,
        "Building_FeudalAge_Home_Small_2": Capital_Small_Home_Lv26,
        "Building_FeudalAge_Home_Small_3": Capital_Small_Home_Lv27,
    },
    "Medium Home": {
        "Building_FeudalAge_Home_Average_1": Capital_Average_Home_Lv25,
        "Building_FeudalAge_Home_Average_2": Capital_Average_Home_Lv26,
        "Building_FeudalAge_Home_Average_3": Capital_Average_Home_Lv27,
    },
    "Luxurious Home": {
        "Building_FeudalAge_Home_Premium_1": Capital_Luxurious_Home_Lv27,
    },
    "Rural Farm": {
        "Building_FeudalAge_Farm_Rural_1": Capital_Rural_Farm_Lv25,
        "Building_FeudalAge_Farm_Rural_2": Capital_Rural_Farm_Lv26,
        "Building_FeudalAge_Farm_Rural_3": Capital_Rural_Farm_Lv27,
    },
    "Domestic Farm": {
        "Building_FeudalAge_Farm_Domestic_1": Capital_Domestic_Farm_Lv25,
        "Building_FeudalAge_Farm_Domestic_2": Capital_Domestic_Farm_Lv26,
        "Building_FeudalAge_Farm_Domestic_3": Capital_Domestic_Farm_Lv27,
    },
    "Luxurious Farm": {
        "Building_FeudalAge_Farm_Premium_1": Capital_Luxurious_Farm_Lv27,
    },
    "Moderate Culture Site": {
        "Building_FeudalAge_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv9,
    },
    "Compact Culture Site": {
        "Building_FeudalAge_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv9,
    },
    "Little Culture Site": {
        "Building_FeudalAge_CultureSite_Little_1": Capital_Little_Culture_Site_Lv9,
    },
    "Carpenter": {
        "Building_FeudalAge_Workshop_Carpenter_1": Capital_Carpenter_Lv3,
    },
    "Scribe": {
        "Building_FeudalAge_Workshop_Scribe_1": Capital_Scribe_Lv3,
    },
    "Spice Merchant": {
        "Building_FeudalAge_Workshop_SpiceMerchant_1": Capital_Spice_Merchant_Lv3,
    },
    "Infantry Barracks": {
        "Building_FeudalAge_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv9,
    },
    "Ranged Barracks": {
        "Building_FeudalAge_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv9,
    },
    "Cavalry Barracks": {
        "Building_FeudalAge_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv9,
    },
    "Heavy Infantry Barracks": {
        "Building_FeudalAge_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv9,
        "Building_FeudalAge_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv9,
        "Building_FeudalAge_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv9,
    },
    "Siege Barracks": {
        "Building_FeudalAge_Barracks_Siege_1": Capital_Siege_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Siege_2": Capital_Siege_Barracks_Lv9,
        "Building_FeudalAge_Barracks_Siege_3": Capital_Siege_Barracks_Lv9,
    },
    "Hero Academy": {
        "Building_FeudalAge_Special_HeroAcademy_1": "",
    },
}

const iberianEraBuildings = {
    "Small Home": {
        "Building_IberianEra_Home_Small_1": Capital_Small_Home_Lv28,
        "Building_IberianEra_Home_Small_2": Capital_Small_Home_Lv29,
        "Building_IberianEra_Home_Small_3": Capital_Small_Home_Lv30,
    },
    "Medium Home": {
        "Building_IberianEra_Home_Average_1": Capital_Average_Home_Lv28,
        "Building_IberianEra_Home_Average_2": Capital_Average_Home_Lv29,
        "Building_IberianEra_Home_Average_3": Capital_Average_Home_Lv30,
    },
    "Luxurious Home": {
        "Building_IberianEra_Home_Premium_1": Capital_Luxurious_Home_Lv30,
    },
    "Rural Farm": {
        "Building_IberianEra_Farm_Rural_1": Capital_Rural_Farm_Lv28,
        "Building_IberianEra_Farm_Rural_2": Capital_Rural_Farm_Lv29,
        "Building_IberianEra_Farm_Rural_3": Capital_Rural_Farm_Lv30,
    },
    "Domestic Farm": {
        "Building_IberianEra_Farm_Domestic_1": Capital_Domestic_Farm_Lv28,
        "Building_IberianEra_Farm_Domestic_2": Capital_Domestic_Farm_Lv29,
        "Building_IberianEra_Farm_Domestic_3": Capital_Domestic_Farm_Lv30,
    },
    "Luxurious Farm": {
        "Building_IberianEra_Farm_Premium_1": Capital_Luxurious_Farm_Lv30,
    },
    "Moderate Culture Site": {
        "Building_IberianEra_CultureSite_Moderate_1":Capital_Moderate_Culture_Site_Lv10,
    },
    "Compact Culture Site": {
        "Building_IberianEra_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv10,
    },
    "Little Culture Site": {
        "Building_IberianEra_CultureSite_Little_1": Capital_Little_Culture_Site_Lv10,
    },
    "Carpenter": {
        "Building_IberianEra_Workshop_Carpenter_1": Capital_Carpenter_Lv4,
    },
    "Scribe": {
        "Building_IberianEra_Workshop_Scribe_1": Capital_Scribe_Lv4,
    },
    "Spice Merchant": {
        "Building_IberianEra_Workshop_SpiceMerchant_1": Capital_Spice_Merchant_Lv4,
    },
    "Infantry Barracks": {
        "Building_IberianEra_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv10,
        "Building_IberianEra_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv10,
        "Building_IberianEra_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv10,
    },
    "Ranged Barracks": {
        "Building_IberianEra_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv10,
        "Building_IberianEra_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv10,
        "Building_IberianEra_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv10,
    },
    "Cavalry Barracks": {
        "Building_IberianEra_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv10,
        "Building_IberianEra_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv10,
        "Building_IberianEra_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv10,
    },
    "Heavy Infantry Barracks": {
        "Building_IberianEra_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv10,
        "Building_IberianEra_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv10,
        "Building_IberianEra_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv10,
    },
    "Siege Barracks": {
        "Building_IberianEra_Barracks_Siege_1": Capital_Siege_Barracks_Lv10,
        "Building_IberianEra_Barracks_Siege_2": Capital_Siege_Barracks_Lv10,
        "Building_IberianEra_Barracks_Siege_3": Capital_Siege_Barracks_Lv10,
    },
    "Hero Academy": {
        "Building_IberianEra_Special_HeroAcademy_1": "",
    },
}

const kingdomOfSicilyBuildings = {
    "Small Home": {
        "Building_KingdomOfSicily_Home_Small_1": Capital_Small_Home_Lv31,
        "Building_KingdomOfSicily_Home_Small_2": Capital_Small_Home_Lv32,
        "Building_KingdomOfSicily_Home_Small_3": Capital_Small_Home_Lv33,
    },
    "Medium Home": {
        "Building_KingdomOfSicily_Home_Average_1": Capital_Average_Home_Lv31,
        "Building_KingdomOfSicily_Home_Average_2": Capital_Average_Home_Lv32,
        "Building_KingdomOfSicily_Home_Average_3": Capital_Average_Home_Lv33,
    },
    "Luxurious Home": {
        "Building_KingdomOfSicily_Home_Premium_1": Capital_Luxurious_Home_Lv33,
    },
    "Rural Farm": {
        "Building_KingdomOfSicily_Farm_Rural_1": Capital_Rural_Farm_Lv31,
        "Building_KingdomOfSicily_Farm_Rural_2": Capital_Rural_Farm_Lv32,
        "Building_KingdomOfSicily_Farm_Rural_3": Capital_Rural_Farm_Lv33,
    },
    "Domestic Farm": {
        "Building_KingdomOfSicily_Farm_Domestic_1": Capital_Domestic_Farm_Lv31,
        "Building_KingdomOfSicily_Farm_Domestic_2": Capital_Domestic_Farm_Lv32,
        "Building_KingdomOfSicily_Farm_Domestic_3": Capital_Domestic_Farm_Lv33,
    },
    "Luxurious Farm": {
        "Building_KingdomOfSicily_Farm_Premium_1": Capital_Luxurious_Farm_Lv33,
    },
    "Moderate Culture Site": {
        "Building_KingdomOfSicily_CultureSite_Moderate_1": Capital_Moderate_Culture_Site_Lv11,
    },
    "Compact Culture Site": {
        "Building_KingdomOfSicily_CultureSite_Compact_1": Capital_Compact_Culture_Site_Lv11,
    },
    "Little Culture Site": {
        "Building_KingdomOfSicily_CultureSite_Little_1": Capital_Little_Culture_Site_Lv11,
    },
    "Carpenter": {
        "Building_KingdomOfSicily_Workshop_Carpenter_1": Capital_Carpenter_Lv5,
    },
    "Scribe": {
        "Building_KingdomOfSicily_Workshop_Scribe_1": Capital_Scribe_Lv5,
    },
    "Spice Merchant": {
        "Building_KingdomOfSicily_Workshop_SpiceMerchant_1": Capital_Spice_Merchant_Lv5,
    },
    "Infantry Barracks": {
        "Building_KingdomOfSicily_Barracks_Infantry_1": Capital_Infantry_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Infantry_2": Capital_Infantry_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Infantry_3": Capital_Infantry_Barracks_Lv11,
    },
    "Ranged Barracks": {
        "Building_KingdomOfSicily_Barracks_Ranged_1": Capital_Ranged_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Ranged_2": Capital_Ranged_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Ranged_3": Capital_Ranged_Barracks_Lv11,
    },
    "Cavalry Barracks": {
        "Building_KingdomOfSicily_Barracks_Cavalry_1": Capital_Cavalry_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Cavalry_2": Capital_Cavalry_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Cavalry_3": Capital_Cavalry_Barracks_Lv11,
    },
    "Heavy Infantry Barracks": {
        "Building_KingdomOfSicily_Barracks_HeavyInfantry_1": Capital_Heavy_Infantry_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_HeavyInfantry_2": Capital_Heavy_Infantry_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_HeavyInfantry_3": Capital_Heavy_Infantry_Barracks_Lv11,
    },
    "Siege Barracks": {
        "Building_KingdomOfSicily_Barracks_Siege_1": Capital_Siege_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Siege_2": Capital_Siege_Barracks_Lv11,
        "Building_KingdomOfSicily_Barracks_Siege_3": Capital_Siege_Barracks_Lv11,
    },
    "Hero Academy": {
        "Building_KingdomOfSicily_Special_HeroAcademy_1": "",
    },
}

export { stoneAgeBuildings, bronzeAgeBuildings, minoanEraBuildings, classicGreeceBuildings, earlyRomeBuildings, romanEmpireBuildings, byzantineEraBuildings, ageOfTheFranksBuildings, feudalAgeBuildings, iberianEraBuildings, kingdomOfSicilyBuildings };